import React from "react";
import { Alert, Loading } from "@website2018/da-dobsonville";
import { authenticated } from "react-u5auth";
import { AuthContext } from "react-u5auth";
import { useFetch } from "../../common/utils";
import { getLocalToken } from "react-u5auth";

const url =
  process.env.RAZZLE_GET_PERSON_URL ||
  "https://membership-api.da-io.net/private/current";

export const AuthWrapper = props => {
  const [user, loading, error] = useFetch(url, {
    headers: {
      authorization: `Bearer ${getLocalToken()}`
    }
  });

  if (loading) return <Loading minHeight={false} />;

  if (error)
    return (
      <Alert status="danger" minHeight={false}>
        {error}
      </Alert>
    );

  return props.children(user || {});
};

const ProtectedComponent = authenticated()(AuthWrapper);

export class AuthComponent extends React.Component {
  render() {
    if (!process.browser) return null;
    if (this.props.isAuthed) {
      return (
        <AuthContext
          clientId={
            process.env.RAZZLE_AUTH_CLIENT_ID || "4db1574fe2faf1dc8d3fa2efd681"
          }
          provider={"https://login.voteda.org"}
        >
          <ProtectedComponent>{this.props.children}</ProtectedComponent>
        </AuthContext>
      );
    }

    return this.props.children(null);
  }
}

AuthComponent.defaultProps = {
  isAuthed: false
};
