import React from 'react'
import { Amount, Label } from '@website2018/da-dobsonville';

const DonorForm = (props) => {
  return (
    <Amount amounts={props.donation_options}>
      <div>
        <Label>Other amount</Label>
        <div className="input-group">
          <span className="input-group-addon" id="basic-addon1">R</span>
          <input
            type="text"
            className="form-control"
            id="amount"
            value=""
          />
        </div>
      </div>
    </Amount>
  )
}

DonorForm.defaultProps = {
  meta: null,
  button_label: 'Donate Now',
  url: '/donate',
  donation_options: null
}

export default DonorForm
