import React from 'react';
import { NavbarSub } from "@website2018/da-dobsonville";

const SubMenu = ({ children,bgColor }) => {

  if (!children) return null;

  return (
    <NavbarSub>
      { children }
    </NavbarSub>
  )
}

export default SubMenu;
