import React from 'react'
import { CardPerson, MoreLink, SectionFull, variables } from '@website2018/da-dobsonville';
import Spacing from '../Spacing/Spacing'

const PeopleList = ({ selectPerson = null, people, title = null, wrapClass = null, sectionProps = {}, cardProps = {}}) => {

  let cn = 'col-md-6'

  if (!people.length)
    return null;

  if (people.length > 20) {
    cn = "col-md-4"
  }

  cn = wrapClass ?  wrapClass : cn;

  const { spacing } = variables;

  return (
    <SectionFull
      title={title}
      color={"blue"}
      bgColor={"light"}
      mb={"none"}
      pb={"large"}
      {...sectionProps}
    >
      {
        people.length && people.map(p => (
          <div className={cn}>
            <CardPerson
              imageUrl={p.featured_media ? p.featured_media.source_url : null}
              title={p.rawTitle || p.title }
              meta={ p.role  }
              border={"base"}
              {...cardProps}
            >
            {
              selectPerson ?
              <Spacing bottom size={spacing.space2}>
                  <span onClick={() => selectPerson(p)}><MoreLink  mb="medium">View More</MoreLink></span>
              </Spacing>
              : null
            }
            </CardPerson>
          </div>
        ))
      }
    </SectionFull>
  )
}




export default PeopleList
