import React from "react";
import {
  variables,
  Copy,
  Hero,
  NavItem,
  Sidebar,
  SectionFull,
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import Sticky from 'react-stickynode';
import PubRepsContainer from '../../containers/Data/PubRepsContainer'
import SubMenu from '../Nav/SubMenu'
import ListItem from '../Nav/ListItem'
import PeopleList from "./PeopleList";
import PersonModal from "./PersonModal";

class Mpls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      person: null,
      query: {
        legislature: "EC"
      }
    };
  }

  render() {
    const { page: p } = this.props;
    const { colors, fontWeight } = variables;

    return [
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu items={p.parent && p.parent.children}>
          {p.parent && (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.textBase}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.parent.slug}`}>National Leadership</Link>
            </NavItem>
          )}
          {p.parent &&
            p.parent.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.parent.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
          {p.children &&
            p.children.length > 0 &&
            p.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
        </SubMenu>
      </Sticky>,
      <Hero
        imageUrl={p.featured_media ? p.featured_media.source_url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      />,
      <SectionFull mt={"none"}>
        <div className={"col-md-8"}>
          <Copy intro html={p.content.rendered || p.parent.content.rendered} />
        </div>
      </SectionFull>,

      <SectionFull key="mopl" bgColor={"light"} pt={"large"} mb={"none"}>
        <div className="col-md-3">
          <div>
            <ListItem
              isActive={this.state.query.legislature === "EC"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "EC" } })
              }
              title="Eastern Cape"
            />
            <ListItem
              isActive={this.state.query.legislature === "FS"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "FS" } })
              }
              title="Free State"
            />
            <ListItem
              isActive={this.state.query.legislature === "GP"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "GP" } })
              }
              title="Gauteng"
            />
            <ListItem
              isActive={this.state.query.legislature === "ZN"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "ZN" } })
              }
              title="KwaZulu-Natal"
            />
            <ListItem
              isActive={this.state.query.legislature === "LP"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "LP" } })
              }
              title="Limpopo"
            />
            <ListItem
              isActive={this.state.query.legislature === "MP"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "MP" } })
              }
              title="Mpumalanga"
            />
            <ListItem
              isActive={this.state.query.legislature === "NC"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "NC" } })
              }
              title="Northern Cape"
            />
            <ListItem
              isActive={this.state.query.legislature === "NW"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "NW" } })
              }
              title="North West"
            />
            <ListItem
              isActive={this.state.query.legislature === "WC"}
              onClick={() =>
                this.setState({ person: null, query: { legislature: "WC" } })
              }
              title="Western Cape"
            />
          </div>
        </div>
        <Sidebar col="col-md-8 ml-auto">
          <PubRepsContainer
            type={"portfolio"}
            query={{
              ...this.state.query
            }}
            render={people => (
              <PeopleList
                wrapClass="col-md-6"
                sectionProps={{ bgColor: null, container: false }}
                people={people}
                selectPerson={person => this.setState({ person, isOpen: true })}
              />
            )}
          />
        </Sidebar>
      </SectionFull>,
      <PersonModal
        closeModal={() => this.setState({ person: null, isOpen: false })}
        isOpen={this.state.isOpen}
        p={this.state.person}
      />
    ];
  }
}

Mpls.defaultProps = {};

export default Mpls;
