import React, { Fragment } from "react";
import {
  ArticleHeader,
  Border,
  Copy,
  NavItem,
  NavIcon,
  SectionFull,
  Sidebar,
  variables,
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import Stickers from "./Stickers";
import ArticleFooter from "../Newsroom/ArticleFooter";
import Asides from '../Asides/Asides';
import ListWrapper from '../Nav/List';
import ListItem from '../Nav/ListItem';
import Spacing from '../Spacing/Spacing';
import SubMenu from '../Nav/SubMenu';
import NoSSR from '../Nav/NoSSR';
import Layout from '../Layout/Base';
import PoliciesDataContainer from "../../containers/Data/PoliciesDataContainer";

class PolicyTemplate extends React.Component {
  render() {
    const { fontWeight, colors } = variables;
    const { page, asides, policy: p } = this.props;
    // console.log("xxx p:", p);
    // console.log("xxx page:", page);

    return [
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu>
          <NavItem
            fontWeight={fontWeight.medium}
            color={colors.textBase}
            fs={"13px"}
            lh={"16px"}
          >
            <Link to={`/${page.parent.slug}`}>
              {page.parent.title.rendered}
            </Link>
          </NavItem>
          {page.parent.children.map((c) => (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.linkBlue}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${c.parent.slug}/${c.slug}`}>{c.title.rendered}</Link>
            </NavItem>
          ))}
        </SubMenu>
      </Sticky>,

      <SectionFull mt={"medium"}>
        <div className={"col-lg-7"}>
          <ArticleHeader
            subHeading={
              p.category && p.category.length
                ? `${p.category.map((p) => p.name).join(" | ")}`
                : null
            }
            title={p.title.rendered}
          />

          {p.stickers && <Stickers items={p.stickers} />}

          <article className="entry">
            <Copy intro html={p.content.rendered} />
            {p.acf.layout_content && p.acf.layout_content.length ? (
              <Layout layout={p.acf.layout_content} />
            ) : null}
          </article>

          {p.download && (
            <Fragment>
              <Border mt="none" mb="none" color={"whiteO"} />
              <a href={p.download} target={"_blank"}>
                <NavIcon
                  iconName="download"
                  iconColor={colors.brandRed}
                  color={colors.brandRed}
                >
                  {"Download Policy Document"}
                </NavIcon>
              </a>
            </Fragment>
          )}

          <Border mt={"large"} mb={"large"} />

          <NoSSR>
            <ArticleFooter p={p} />
          </NoSSR>
        </div>

        <Sidebar>
          <PoliciesDataContainer
            group={true}
            render={(items, groups) => (
              <ListWrapper title={"More Policies"}>
                {Object.keys(items).map((item) => (
                  <ListItem title={item} collapsed>
                    {items[item].map((c) => (
                      <Spacing top bottom size="space2">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link to={`/policy/${c.slug}`}>
                            <Copy hover html={c.title.rendered} />
                          </Link>
                          {c.download && (
                            <a href={c.download} target={"_blank"}>
                              <NavIcon
                                iconColor={colors.brandRed}
                                iconName="download"
                              />
                            </a>
                          )}
                        </div>
                      </Spacing>
                    ))}
                  </ListItem>
                ))}
              </ListWrapper>
            )}
          />

          {asides && <Asides asides={asides} />}
        </Sidebar>
      </SectionFull>,
    ];
  }
}

export default PolicyTemplate;
