import React from "react";
import { variables, Widget, NavIcon } from "@website2018/da-dobsonville";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from "react-share";
import qs from "qs";

const getFacebookOptions = (url, { facebook_options = {} }) => {
  const options = {
    ref: facebook_options.tracking_code || null
  };

  if (options.ref) {
    return {
      url: `${url}?${qs.stringify(options)}`
    };
  }

  return {
    url
  };
};

const getTwitterOptions = (url, { twitter_options = {} }) => {
  const options = {
    ref: twitter_options.twitter_id || null
  };

  if (options.ref) {
    return {
      url: `${url}?${qs.stringify(options)}`,
      title: twitter_options.tweet_text
    };
  }

  return {
    url,
    title: twitter_options.tweet_text
  };
};

const getWhatsAppOptions = (url, { whatsapp_options = {} }) => {
  const options = {
    ref: whatsapp_options.tracking_code || null
  };

  if (options.ref) {
    return {
      url: `${url}?${qs.stringify(options)}`,
      title: whatsapp_options.content,
      separator: " - "
    };
  }

  return {
    url,
    title: whatsapp_options.content,
    separator: " - "
  };
};

const SuccessShare = ({ title, url, co, children }) => {
  const { colors } = variables;

  return (
    <aside>
      {co && (
        <Widget
          widgetTitle={title}
          widgetTitleColor={"blue"}
          colTitle={"col-md-12"}
          colSection={"col-md-12"}
        >
          {co.display_facebook && (
            <FacebookShareButton {...getFacebookOptions(url, co)}>
              <NavIcon iconType="bootstrap" iconName="facebook" iconColor={colors.socialFacebook}>
                {"Share on Facebook"}
              </NavIcon>
            </FacebookShareButton>
          )}
          {co.display_twitter && (
            <TwitterShareButton {...getTwitterOptions(url, co)}>
              <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>
                {"Share on X (Twitter)"}
              </NavIcon>
            </TwitterShareButton>
          )}
          {co.display_whatsapp && (
            <WhatsappShareButton {...getWhatsAppOptions(url, co)}>
              <NavIcon iconType="bootstrap" iconName="whatsapp" iconColor={colors.socialWhatsApp}>
                {"Share with WhatsApp"}
              </NavIcon>
            </WhatsappShareButton>
          )}
        </Widget>
      )}
      {children}
    </aside>
  );
};

SuccessShare.defaultProps = {
  title: "Share"
};
export default SuccessShare;
