import React, { Fragment } from "react";
import {
  Copy,
  Hero,
  SectionFull,
  Sidebar,
  variables,
  Widget,
  NavIcon
} from "@website2018/da-dobsonville";
import { noComma } from "../../common/utils";

import Actions from '../Actions/Actions'
import Asides from '../Asides/Asides'
import Layout from '../Layout/Base'
import Spacing from '../Spacing/Spacing'

import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";

const SuccessTemplate = props => {
  const { page } = props;
  const { page: p } = props;
  const { colors, spacing } = variables;
  let url = window ? window.location.href : null;

  return [
    <Hero
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      mb={
        p.hero && p.hero.background_image && p.hero.background_image.url
          ? "large"
          : "none"
      }
    >
      {page.hero.cta_type === "Group" ? (
        <Actions
          type={page.hero.cta_type}
          actions={page.hero.cta_group}
          headline={page.hero.group_headline}
        />
      ) : (
        <Actions
          type={page.hero.cta_type}
          headline={page.hero.countdown.headline}
          date={page.hero.countdown.date}
        />
      )}
    </Hero>,
    <Fragment>
      {p.content.rendered && (
        <SectionFull mt={"none"}>
          <div className={"col-lg-7"}>
            <article className="entry">
              <Copy intro html={p.content.rendered} />
              {p.acf.layout_content && p.acf.layout_content.length ? (
                <Layout layout={p.acf.layout_content} />
              ) : null}
            </article>

            <Spacing margin top size={spacing.space4}>
              <Widget
                widgetTitle={null}
                widgetTitleColor={"blue"}
                colTitle={"col-md-12"}
                colSection={"col-md-12"}
              >
                <div className="row">
                  <div className="col-md-6">
                    <FacebookShareButton url={url}>
                      <NavIcon
                        iconType="bootstrap"
                        iconName="facebook"
                        iconColor={colors.socialFacebook}
                      >
                        {"Share on Facebook"}
                      </NavIcon>
                    </FacebookShareButton>
                    <WhatsappShareButton title={p.title.rendered} url={url}>
                      <NavIcon
                        iconType="bootstrap"
                        iconName="whatsapp"
                        iconColor={colors.socialWhatsApp}
                      >
                        {"Share with WhatsApp"}
                      </NavIcon>
                    </WhatsappShareButton>
                    <TwitterShareButton url={url}>
                      <NavIcon
                        iconType="bootstrap"
                        iconName="twitter-x"
                        iconColor={colors.socialTwitter}
                      >
                        {"Share on X (Twitter)"}
                      </NavIcon>
                    </TwitterShareButton>
                    <EmailShareButton subject={p.title.rendered} body={url}>
                      <NavIcon
                        iconType="bootstrap"
                        iconName="envelope-fill"
                        iconColor={colors.socialEmail}
                      >
                        {"Send by Email"}
                      </NavIcon>
                    </EmailShareButton>
                  </div>
                </div>
              </Widget>
            </Spacing>
          </div>

          <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
        </SectionFull>
      )}
    </Fragment>
  ];
};

export default SuccessTemplate;
