import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { noComma } from '../../common/utils';
import { Copy, Hero, Loading, SectionFull, Sidebar } from '@website2018/da-dobsonville';

import Asides from '../Asides/Asides';
import AsidesGrid from '../Asides/AsidesGrid';
import SettingsProvider from '../../containers/Settings/SettingsProvider';



class GetInvolvedTemplate extends React.Component {
  render() {

    const { error, loading, page:p } = this.props;

    if (loading) return <Loading/>;
    if (error) return <h1>ERROR</h1>;
    
    return ([
      <Hero
        imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
      />,

      <Fragment>
        { p.content.rendered &&
          <SectionFull>
            <div className={"col-lg-7"}>
              <Copy intro html={p.content.rendered} />
            </div>
          </SectionFull>
        }
      </Fragment>,

      <AsidesGrid asidesGrid={p.asides_grid}/>,

      <SectionFull
        mt={"large"}
        mb={"large"}
        color={"red"}
        >
        <div className={"col-lg-7"}>
          { p.asides &&
            <Asides asides={p.asides.filter(a => a.aside_options.type === 'Menu')} />
          }
        </div>

        <Sidebar>
          { p.asides &&
            <Asides asides={p.asides.filter(a => a.aside_options.type !== 'Menu')} />
          }
        </Sidebar>
      </SectionFull>,
    ]);
  }
}

GetInvolvedTemplate.defaultProps = {
}

GetInvolvedTemplate.propTypes = {
}


GetInvolvedTemplate.contextTypes = {
  settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired,
};

export default GetInvolvedTemplate;
