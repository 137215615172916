import React from "react";
import { ButtonTest } from "@website2018/da-dobsonville";
import Modal from "react-modal";

export const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1060,
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  },
  content: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: "0",
    background: "rgba(255, 255, 255, 1)",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "0px",
    outline: "none",
    padding: "8rem 0 6rem"
  }
};
class ContentModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
      >
        <div className="container">
          {this.props.render ? this.props.render(this.props.data) : null}
          <ButtonTest onClick={this.props.closeModal} outline>
            Close
          </ButtonTest>
        </div>
      </Modal>
    );
  }
}

export default ContentModal;
