import React, { Fragment } from "react";
import {
  Aside,
  Copy,
  Hero,
  SectionFull,
  Widget,
  Sidebar,
  Heading,
  CardCampaign,
  ArticleHeader,
  Border,
  ButtonTest,
  Image,
  MoreLink,
  CardHighlight,
  variables
} from "@website2018/da-dobsonville";
import { noComma } from "../../common/utils";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Asides from '../Asides/Asides'
import MetaHeader from '../Meta/MetaHeader'
import Layout from '../Layout/Base'
import Posts from '../Newsroom/Posts'
import SliderWrapper from "../Slider/Slider";
import Spacing from "../Spacing/Spacing"
import SuccessShare from "../Asides/Share";
import ContentModal from "../Modal/ContentModal";

import FormContainer from "../../containers/Data/FormContainer"
import FormBuilderContainer from "../../containers/Data/FormBuilderContainer"

class CompetitionTemplate extends React.Component {
  state = {
    copied: false,
    data: {
      title: null,
      content: null
    }
  };
  renderShare() {
    const { campaign: p } = this.props;

    const {
      acf: { share_url, campaign_options: co }
    } = p;

    const url = `${share_url}`;

    return (
      <SuccessShare title={"Share link with friends"} co={co} url={url}>
        <Widget
          widgetTitle={"Copy Link"}
          widgetTitleColor={"blue"}
          colTitle={"col-md-12"}
          colSection={"col-md-12"}
        >
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="link"
                placeholder={url}
                readonly
              />

              <CopyToClipboard
                text={url}
                onCopy={() => this.setState({ copied: true })}
              >
                <ButtonTest
                  size="small"
                  customStyles={{ borderRadius: "0 30px 30px 0 !important" }}
                >
                  Copy
                </ButtonTest>
              </CopyToClipboard>
            </div>
            {this.state.copied ? (
              <Copy size="small" color="green">
                Copied
              </Copy>
            ) : null}
          </div>
        </Widget>
      </SuccessShare>
    );
  }

  render() {
    const { campaign: p } = this.props;
    const { spacing } = variables;

    return [
      <MetaHeader p={p} />,

      <Hero
        small={false}
        imageUrl={
          p.hero && p.hero.background_image ? p.hero.background_image.url : null
        }
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      />,

      <Fragment>
        <SectionFull mt={"none"}>
          <div className={"col-lg-7"}>
            <article className="entry">
              {p.content ? <Copy intro html={p.content.rendered} /> : null}

              <FormContainer
                formId='powertotheregistered'
                render={
                (form, onSubmit, meta) => (
                  <FormBuilderContainer inputClass="col-md-12" form={form} />
                )}
              />

              {p.campaign_gallery && (
                <Fragment>
                  <Spacing top size={spacing.space4}>
                    <Heading mb="medium" color="blue">
                      {p.campaign_gallery.headline}{" "}
                    </Heading>
                    <SliderWrapper col="col-md-12">
                      {p.campaign_gallery.images &&
                        p.campaign_gallery.images.map(t => (
                          <Spacing bottom size={spacing.space2}>
                            <Image imageUrl={t.url} />
                          </Spacing>
                        ))}
                    </SliderWrapper>
                  </Spacing>
                </Fragment>
              )}
              {p.acf.layout_content && p.acf.layout_content.length ? (
                <Layout layout={p.acf.layout_content} />
              ) : null}
            </article>
          </div>
          <Sidebar>
            {p.asides && <Asides asides={p.asides} page={p} />}
            {p.acf.display_campaign_options ? this.renderShare() : null}
          </Sidebar>
        </SectionFull>
      </Fragment>,

      p.acf.layout && p.acf.layout.length ? (
        <Layout layout={p.acf.layout} />
      ) : null,

      p.posts && <Posts posts={p.posts} title={"Related News"} />
    ];
  }
}

export default CompetitionTemplate;
