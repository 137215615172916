import React from 'react'
import { Sticker, SectionFull } from '@website2018/da-dobsonville';

const Stories = ({ stories }) => {

  if (!stories) return null;

  const { items = [], title } = stories;

  if (!items.length) return null;

  return (
    <SectionFull
      title={title || null}
      color={"red"}
    >
      <div className={"col-md-12"}>
        <div className={"card-columns"}>
          {
            items.map((s,index) => (
              <Sticker
                key={`story-${index}`}
                color={"red"}
                title={s.headline}
                content={s.content}
                imageUrl={s.image.sizes ? s.image.sizes.large : null}
                icon={s.icon || null}
              />
            ))
          }

        </div>
      </div>
    </SectionFull>
  )
}

export default Stories
