import React, { useEffect, useState } from "react";
import { groupBy } from "ramda";
import { Loading, Label, Copy } from "@website2018/da-dobsonville";
import { useFetch } from "../../common/utils";
const byProvice = groupBy(campus => {
  return campus.properties.Province;
});

const byInstitition = groupBy(campus => {
  return campus.properties.Institution;
});

const CampusSelector = ({ id, error }) => {
  const [campus, setCampus] = useState({
    province: null,
    institution: null,
    campus: null
  });

  const [posts, loading] = useFetch(
    "https://static.da-io.net/Higher_Education_Institution_Database.geojson"
  );

  const { features } = posts;

  if (loading) return <Loading minHeight={false} />;

  const provinces = byProvice(features);
  let institutions = [];
  let campuses = [];

  if (campus.province) {
    institutions = byInstitition(provinces[campus.province]);
  }

  if (campus.province && campus.institution) {
    campuses = features.filter(p => {
      return (
        p.properties.Province === campus.province &&
        p.properties.Institution === campus.institution
      );
    });
  }

  return (
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Province</Label>
        <select
          className="form-control"
          onChange={e =>
            setCampus({
              ...campus,
              province: e.target.value
            })
          }
        >
          <option />
          {Object.keys(provinces).map(province => (
            <option value={province}>{province}</option>
          ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <Label>Institutions</Label>
        <select
          className="form-control"
          disabled={!Object.keys(institutions).length}
          onChange={e =>
            setCampus({
              ...campus,
              institution: e.target.value
            })
          }
        >
          <option />
          {Object.keys(institutions).map(institution => (
            <option value={institution}>{institution}</option>
          ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <Label>Campus</Label>
        <select
          className="form-control"
          disabled={!campuses.length}
          onChange={e =>
            setCampus({
              ...campus,
              campus: e.target.value
            })
          }
        >
          <option />
          {campuses.map(campus => (
            <option value={campus.properties.Campus}>
              {campus.properties.Campus}
            </option>
          ))}
        </select>
      </div>

      {error && (
        <div className="col-md-12">
          <Copy mb="small" color="red" size="small">
            {error}
          </Copy>
        </div>
      )}

      <input type="hidden" name={`${id}[province]`} value={campus.province} />
      <input
        type="hidden"
        name={`${id}[institution]`}
        value={campus.institution}
      />
      <input type="hidden" name={`${id}[campus]`} value={campus.campus} />
    </div>
  );
};

export default CampusSelector;
