import React from "react";
import { Copy } from "@website2018/da-dobsonville";
const Terms = () => {
  return (
    <div className="container entry" style={{ minHeight: "80vh" }}>
      <h6>Terms and Conditions for applying for membership</h6>
      <Copy>
        <p>
          Please make sure you read and understand the following section from
          the Democratic Alliance Student Organisation Rules in order to qualify
          for membership:
        </p>
        <p>6.1.1 Any person wishing to become a member of DASO must:</p>
        <p>
          6.1.1.1 Be a registered student at an institution of higher education
          and training in South Africa or; a Constituency Leader of a DASO
          Constituency, or a Councillor allocated to a DASO Constituency or a
          Democratic Alliance Parliamentary Spokesperson on Higher Education and
          Training;
        </p>
        <p>6.1.1.2 Subscribe to the Vision, Principles and Purpose of DASO;</p>
        <p>
          6.1.1.3 Agree to abide by the provisions of the Rules and Regulations
          of DASO;
        </p>
        <p>
          6.1.1.4 Apply inwriting by completing the prescribed membership form,
          including the Anti-Racism Pledge;
        </p>
        <p>
          6.1.1.5 Not be a member of any other political student organisation;
        </p>
        <p>
          6.1.1.6 Agree to be bound by the Federal Constitution of the
          Democratic Alliance and accept that where there may be any ambiguity
          or conflict of interpretation between these rules and the Federal
          Constitution of the Democratic Alliance, that effect will be given to
          the word in and interpretation of the similar clause in the Federal
          Constitution. (except for s3.1.1.2 for foreign students;)
        </p>
      </Copy>
    </div>
  );
};

export default Terms;
