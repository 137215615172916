import React from "react";
import {
  variables,
  Copy,
  Hero,
  NavItem,
  SectionFull
} from "@website2018/da-dobsonville";
import { Ward } from "@website2018/sc-component";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import SubMenu from '../Nav/SubMenu';
import NoSSR from '../Nav/NoSSR';
import { copyHash } from "../../common/utils";

class WCouncillor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      person: null,
      query: {
        legislature: "EC"
      }
    };
  }

  render() {
    const { page: p } = this.props;
    const { colors, fontWeight } = variables;
    return [
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu items={p.parent && p.parent.children}>
          {p.parent && (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.textBase}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.parent.slug}`}>National Leadership</Link>
            </NavItem>
          )}
          {p.parent &&
            p.parent.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.parent.slug}/${c.slug}`}>
                  {c.title.rendered}
                </Link>
              </NavItem>
            ))}
          {p.children &&
            p.children.length > 0 &&
            p.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
        </SubMenu>
      </Sticky>,
      <Hero
        imageUrl={p.featured_media ? p.featured_media.source_url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      />,
      <SectionFull mt={"none"}>
        <div className={"col-md-8"}>
          <Copy intro html={p.content.rendered || p.parent.content.rendered} />
        </div>
      </SectionFull>,

      <NoSSR>
        <Ward
          title={copyHash.wardHeadline}
          description={copyHash.wardDescription}
        />
      </NoSSR>
    ];
  }
}

WCouncillor.defaultProps = {};

export default WCouncillor;
