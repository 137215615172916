import React, { Fragment } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { noComma } from '../../common/utils'
import { Copy, Hero, HeadingLines, Heading, Loading, SectionFull, Sidebar } from '@website2018/da-dobsonville';

import Asides from '../Asides/Asides';
import Campaigns from '../Campaigns/Campaigns'
import ListWrapper from '../Nav/List';
import ListItem from '../Nav/ListItem';
import Spacing from '../Spacing/Spacing'

class DonateTemplate extends React.Component {
  render() {
    const { campaigns, donate, error, loading, page:p } = this.props;

    if (loading) return <Loading/>;
    if (error) return <h1>ERROR</h1>;

    return ([
      <Hero
        imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
      />,

      <Fragment>
        { p.content.rendered &&
          <SectionFull mt={"none"}>
            <div className={"col-lg-7"}>
              <Copy intro html={p.content.rendered} />
              <SectionFull
                mb={"none"}
                mt={"large"}
                >
                <Campaigns
                  campaigns={campaigns}
                  title={"Contribute to these Campaigns"}
                />
                <SectionFull
                  mt={"medium"}
                  container="">
                  <div className={"col-md-12"}>
                    <HeadingLines lineLeft mb={"large"}>
                      <Heading
                        tracking={"huge"}
                        size={"tiny"}
                        weight={"regular"}
                        uppercase>
                        { donate.donate && donate.donate.section_heading }
                      </Heading>
                    </HeadingLines>

                    <Copy html={ donate.donate && donate.donate.text }/>

                    <ListWrapper>
                      { donate.donate && donate.donate.offline_options.map(r =>
                        <ListItem
                          key={r.key}
                          title={r.title}
                          collapsed>
                          <Spacing top size={"space2"}>
                            <Copy weight="bold">{r.description}</Copy>
                            <table className="table table-sm table-hover">
                              <tbody>
                                { r.content.map(c =>
                                  <tr>
                                    <td><Copy mb={"none"}>{c.label}</Copy></td>
                                    <td><Copy mb={"none"}>{c.detail}</Copy></td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </Spacing>
                        </ListItem>
                      )}
                    </ListWrapper>
                  </div>
                </SectionFull>
              </SectionFull>
            </div>

            <Sidebar>
              { p.asides &&
                <Asides asides={p.asides} />
              }
            </Sidebar>

          </SectionFull>

        }

      </Fragment>,

    ]);
  }
}


const TEMPLATE_QUERY = gql`
  query getTemplate {
    donate: page(slug: "donate"){
      id
      donate{
        section_heading
        text
        offline_options{
          title
          description
          content{
            label
            detail
          }
        }
      }
    }
    campaigns(meta_key:"campaign_status", meta_value:"current") {
      id
      slug
      title {
        rendered
      }
      external{
        status
        link
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
  			title {
  			  rendered
  			}
      }
    },
  }
`;


const withTemplate = graphql(TEMPLATE_QUERY, {
  props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(DonateTemplate);
