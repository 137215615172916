import React, { Fragment } from 'react'
import { CardCampaign, Copy, Hero, SectionFull, Testimonial, Sidebar } from '@website2018/da-dobsonville';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Link } from 'react-router-dom';
import { noComma } from '../../common/utils';

import Asides from '../Asides/Asides'
import ListItem from '../Nav/ListItem'
import Posts from '../Newsroom/Posts'
import Stickers from '../Government/Stickers'
import Spacing from '../Spacing/Spacing'
import SliderWrapper from '../Slider/Slider'
import Videos from '../Videos/Videos'

import NavigationContainer from '../../containers/Nav/NavigationContainer';

const WWGTemplate = (props) => {
  const { page:p, videos, archive: posts, loading } = props;

  return ([
    <Hero
      imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
      mb={p.hero && p.hero.background_image ? "large" : "none"}
    />,

    <Fragment>
      { p.content.rendered &&
        <SectionFull>
          <div className={"col-lg-7"}>
            <Copy intro html={p.content.rendered} />
          </div>
        </SectionFull>
      }
    </Fragment>,

    <Fragment>
      { p.testimonials.testimonial && p.testimonials.testimonial.length ?
        <SectionFull
          bgColor={"light"}
          pt={"large"}
          pb={"large"}
          mb={"large"}
          container={"container"}>
          <SliderWrapper>
            { p.testimonials.testimonial.map(t =>
              <div>
                <Testimonial
                  headline={t.headline}
                  text={t.text}
                  imageUrl={(t.photo.sizes && t.photo.sizes.medium) || t.photo.url}
                  meta={t.attribution}
                />
              </div>
            )}
         </SliderWrapper>
       </SectionFull>
       : null
      }
    </Fragment>,

    p.stickers && p.stickers.length &&
    <SectionFull mb="large" color="red" title={`${p.title.rendered} Success Stories`}>
      <div className="col-md-12">
        { p.stickers.length && <Stickers items={p.stickers} /> }
      </div>
    </SectionFull>,

    <SectionFull
      mb={"large"}
      color={"red"}
      title={p.title.rendered}>
      <NavigationContainer
        menuId={378}
        render={ menu => (
          <div className="col-lg-7">
            {
              menu.items.map(m => (
                <ListItem title={m.title}>
                  <Spacing top bottom size="space2">
                    <div className="row">
                      { m.children.map(c => (<div className="col-sm-6"><Link to={`/government/${c.object_slug}`}><Copy hover html={c.title} override={{display: 'inline-block'}}/></Link></div>)) }
                    </div>
                  </Spacing>
                </ListItem>
              ))
            }
          </div>
        )}
      />

      <Sidebar>
        { p.asides && <Asides asides={p.asides} /> }
      </Sidebar>

    </SectionFull>,

    p.list.hasList &&
    <SectionFull
      title={p.list.section_heading}
      color={"red"}
      mb="large">
      {
        p.list.list_items.length && p.list.list_items.map((l,index) =>
        <div className="col-md-6" key={`${index}-${l.id}`}>
          <CardCampaign
            imageUrl={(l.image.sizes && l.image.sizes.medium_large) || l.image.url}
            title={l.title}
            paragraph={l.text}
          />
        </div>
        )
      }
    </SectionFull>,

    !loading ? <Videos videos={videos} title={`${p.title.rendered} Videos`} /> : null,
    !loading ? <Posts mn={true} more={"/newsroom?page=1&refinementList[taxonomies][category][]=Where%20We%20Govern"} government={"where-we-govern"} posts={posts} title={`${p.title.rendered} Newsroom`} /> : null
  ])
}

const TEMPLATE_QUERY = gql`
  query getTemplate($slug: String!) {
    videos(slug:$slug) {
      id
      videoId
      title {
        rendered
      }
      excerpt {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    },
    archive(input: { post_type: "municipality_news", term: "where-we-govern", taxonomy: "category"}) {
      id
      date
      slug
      formattedDate
      title {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
  			title {
  			  rendered
  			}
        media_details {
          sizes {
            medium { source_url }
          }
        }
      }
    }
  }
`;

const withTemplate = graphql(TEMPLATE_QUERY, {
    options: (props) => ({
        variables: { slug: props.match.params.slug }
      }),
    props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(WWGTemplate);
