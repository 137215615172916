import React, { Fragment } from 'react'
import { ArticleHeader, Border, Copy, NavItem, SectionFull, Sidebar, variables } from '@website2018/da-dobsonville';
import { Link } from 'react-router-dom';
import Asides from '../Asides/Asides'
import MetaHeader from '../Meta/MetaHeader'
import SubMenu from '../Nav/SubMenu'
import PersonFooter from './PersonFooter'
import Media from 'react-media'
import Sticky from 'react-stickynode';
const Member = (props) => {
  const { member:m, page: p } = props;
  const { colors, fontWeight } = variables;

  return (
    <Fragment>
      <MetaHeader p={m} />
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu items={p.parent && p.parent.children}>
          {p && (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.textBase}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.slug}`}>National Leadership</Link>
            </NavItem>
          )}
          {p.children &&
            p.children.length > 0 &&
            p.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
        </SubMenu>
      </Sticky>
      <SectionFull mt={"medium"}>
        <div className={"col-lg-7"}>
          <ArticleHeader
            title={m.title.rendered}
            meta={m.role}
          />
          { m.content.rendered &&
            <article className="entry">
              <Copy intro html={m.content.rendered} />
            </article>
          }
          <Media query="(min-width: 576px)">
              { matches => matches ? (
                <Border
                  mt={"large"}
                  mb={"large"}
                />
              ) : (
                <Border
                  mt={"small"}
                  mb={"medium"}
                />
            )}
          </Media>
          { m.publicRep ? <PersonFooter p={m.publicRep}/> : null }
        </div>

        <Sidebar>
          { m.asides &&
            <Asides asides={m.asides} />
          }
        </Sidebar>
      </SectionFull>
    </Fragment>
  )
}

export default Member;
