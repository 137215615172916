import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import {
  Aside,
  Heading,
  AsideList,
  Loading,
  Widget,
  Card,
  CardNewsMobile,
  CardNews,
  CardPerson
} from "@website2018/da-dobsonville";
import { dateRegex } from "../../common/utils";
import { Link } from "react-router-dom";

class TrendingContainer extends React.Component {
  render() {
    const { error, loading, posts, title } = this.props;

    if (loading) return <Loading />;
    if (error) return null;
    if (posts == null || posts.length == 0) return <React.Fragment />

    const first = posts[posts.length - 1];
    const rest = posts.slice(0, posts.length - 1);

    const [, year, month] = dateRegex.exec(first.date);
    return (
      <Aside vertical title={title}>
        <Link to={`/${year}/${month}/${first.slug}`} key={first.id}>
          <CardNews
            imageUrl={
              first.featured_media
                ? (
                  (first.featured_media.media_details
                      && first.featured_media.media_details.sizes
                      && first.featured_media.media_details.sizes.medium)
                    ? first.featured_media.media_details.sizes.medium.source_url
                    : first.featured_media.source_url
                )
                : null
            }
            title={first.title.rendered}
          ></CardNews>
        </Link>
        <hr />
        {rest.map(p => {
          const [, year, month] = dateRegex.exec(p.date);
          return (
            <Link to={`/${year}/${month}/${p.slug}`} key={p.id}>
              <Heading
                level={3}
                size={"small"}
                mb={"small"}
                tracking={"none"}
                html={p.title.rendered}
              />
              <hr />
            </Link>
          );
        })}
      </Aside>
    );
  }
}

const POSTS_QUERY = gql`
  query getPosts($taxonomy: String!, $term: String!, $limit: Int) {
    posts(taxonomy: $taxonomy, term: $term, first: $limit) {
      id
      slug
      date
      title {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
          sizes {
            medium { source_url }
          }
        }
      }
    }
  }
`;

const withPosts = graphql(POSTS_QUERY, {
  options: props => {
    return {
      variables: {
        taxonomy: props.taxonomy,
        term: props.term,
        limit: parseInt(props.limit)
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default withPosts(TrendingContainer);
