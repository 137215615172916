import React, { Fragment } from 'react'
import { Sticker } from '@website2018/da-dobsonville';
import Media from "react-media";

const Stickers = ({ items, columns }) => {

  if (!items.length) return null;

  return (
    <div className={`card-columns ${columns}`}>
      <Media query="(min-width: 992px)">
        { matches => matches ? (
          <Fragment>
            {
              items.map((s,index) => (
                  <Sticker
                    key={`sticker-${index}`}
                    title={s.title || null}
                    content={s.text || null }
                    imageUrl={s.background.url || null}
                    icon={s.icon || null}
                    color={s.color || null}
                  />

              ))
            }
          </Fragment>
        ) : (
          <Fragment>
            {
              items.slice(0, 3).map((s,index) => (
                  <Sticker
                    key={`sticker-${index}`}
                    title={s.title || null}
                    content={s.text || null }
                    imageUrl={s.background.url || null}
                    icon={s.icon || null}
                    color={s.color || null}
                  />
              ))
            }
          </Fragment>
        )}
      </Media>
    </div>
  )
}

Stickers.defaultProps = {
  items: null,
  columns: 'columns-3',
}

export default Stickers;
