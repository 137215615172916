import React, { Fragment } from "react";
import {
  variables,
  CardHighlight,
  CardPerson,
  Copy,
  Hero,
  MoreLink,
  NavItem,
  NavIcon,
  SectionFull,
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import Sticky from 'react-stickynode';
import PubRepsContainer from '../../containers/Data/PubRepsContainer';
import SubMenu from '../Nav/SubMenu';
import Spacing from '../Spacing/Spacing';
import PeopleList from "./PeopleList";
import { sortBy, compose, prop, toLower } from "ramda";

var sortByNameCaseInsensitive = sortBy(compose(toLower, prop("lastname")));

class ProvincialTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      person: null,
      query: {
        legislature: "EC"
      }
    };
  }

  render() {
    const { page: p } = this.props;
    const { colors, fontWeight, spacing } = variables;
    return [
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu items={p.parent && p.parent.children}>
          {p.parent && (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.textBase}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.parent.slug}`}>National Leadership</Link>
            </NavItem>
          )}
          {p.parent &&
            p.parent.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.parent.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
          {p.children &&
            p.children.length > 0 &&
            p.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
        </SubMenu>
      </Sticky>,
      <Hero
        imageUrl={p.featured_media ? p.featured_media.source_url : null}
        title={p.title.rendered}
      />,
      <SectionFull mt={"none"}>
        <div className={"col-md-8"}>
          <Copy intro html={p.content.rendered || p.parent.content.rendered} />
        </div>
      </SectionFull>,
      <Fragment>
        <SectionFull
          bgColor={"light"}
          pt={"large"}
          mb={"none"}
          title={p.meta.people.heading || null}
        >
          {p.highlight.person ? (
            <div className={"col-md-12"}>
              <CardHighlight
                imageUrl={p.highlight.person.featured_media
                  ? (
                    (p.highlight.person.featured_media.media_details
                        && p.highlight.person.featured_media.media_details.sizes
                        && p.highlight.person.featured_media.media_details.sizes.large)
                      ? p.highlight.person.featured_media.media_details.sizes.large.source_url
                      : p.highlight.person.featured_media.source_url)
                  : null}
                title={p.highlight.person.title.rendered}
                meta={p.highlight.person.role}
                paragraph={p.highlight.person.excerpt.rendered}
              >
                <Spacing bottom size={spacing.space2}>
                  <Link to={`/people/${p.highlight.person.slug}`}>
                    <MoreLink mb="medium">
                      Read More
                    </MoreLink>
                  </Link>
                </Spacing>
                {p.highlight.person.facebook && (
                  <NavIcon
                    iconType="bootstrap"
                    iconName="facebook"
                    iconColor={colors.socialFacebook}
                  >
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href={p.highlight.person.facebook}
                      title={p.highlight.person.facebook}
                    >
                      {p.highlight.person.title.rendered} on Facebook
                    </a>
                  </NavIcon>
                )}
                {p.highlight.person.twitter && (
                  <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href={`https://www.twitter.com/${
                        p.highlight.person.twitter
                      }`}
                      title={p.highlight.person.twitter}
                    >
                      Follow {p.highlight.person.twitter}
                    </a>
                  </NavIcon>
                )}
              </CardHighlight>
            </div>
          ) : null}

          {p.meta.people.connected.length ? (
            <SectionFull bgColor={"light"}>
              {p.meta.people.connected.map(p => (
                <div className="col-md-6">
                  <CardPerson
                    imageUrl={p.featured_media
                      ? (
                        (p.featured_media.media_details
                            && p.featured_media.media_details.sizes
                            && p.featured_media.media_details.sizes.medium)
                          ? p.featured_media.media_details.sizes.medium.source_url
                          : p.featured_media.source_url)
                      : null}
                    title={p.rawTitle || p.title}
                    meta={p.role}
                    border={"base"}
                  >
                    <Spacing bottom size={spacing.space2}>
                      <Link to={`/people/${p.slug}`}>
                        <MoreLink mb="medium">
                          Read More
                        </MoreLink>
                      </Link>
                    </Spacing>
                    {p.facebook && (
                      <NavIcon
                        iconType="bootstrap"
                        iconName="facebook"
                        iconColor={colors.socialFacebook}
                      >
                        <a
                          target="_blank" rel="noopener noreferrer"
                          href={p.person.facebook}
                          title={p.facebook}
                        >
                          {p.title} on Facebook
                        </a>
                      </NavIcon>
                    )}
                    {p.twitter && (
                      <NavIcon
                        iconType="bootstrap"
                        iconName="twitter-x"
                        iconColor={colors.socialTwitter}
                      >
                        <a
                          target="_blank" rel="noopener noreferrer"
                          href={`https://www.twitter.com/${p.twitter}`}
                          title={p.twitter}
                        >
                          Follow {p.twitter}
                        </a>
                      </NavIcon>
                    )}
                  </CardPerson>
                </div>
              ))}
            </SectionFull>
          ) : (
            <PubRepsContainer
              type={typeBySlug(p.slug)}
              query={{
                slug: p.slug
              }}
              render={people => (
                <PeopleList
                  wrapClass="col-md-4"
                  people={sortByNameCaseInsensitive(people)}
                  selectPerson={person =>
                    this.setState({ person, isOpen: true })
                  }
                />
              )}
            />
          )}
        </SectionFull>
      </Fragment>
    ];
  }
}

const typeBySlug = slug => {
  switch (slug) {
    case "national-assembly-members":
      return "portfolio";
    default:
      return "province";
  }
};

ProvincialTemplate.defaultProps = {};

export default ProvincialTemplate;
