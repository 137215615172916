import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from '@website2018/da-dobsonville';

class ArchiveContainer extends React.Component {
  render() {
    const { archive, error, loading } = this.props;

    if (loading) return <Loading/>;
    if (error) return <p>An unexpected error has occured</p>;

    return this.props.render(archive);
  }
}

const POST_QUERY = gql`
  query getArchive($input: InputQuery!) {
    archive(input:$input) {
      id
      slug
      date
      formattedDate
      title {
        rendered
      }
      content {
        rendered
      }
      excerpt {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
  			title {
  			  rendered
  			}
        media_details {
          sizes {
            medium { source_url }
          }
        }
      }
    }
  }
`;



const withPost = graphql(POST_QUERY, {
  options: (props) => ({
    variables: {
      input: {
        post_type: props.type || 'posts',
        limit: props.limit || 5,
        taxonomy: props.taxonomy || null,
        term: props.term || null,
        exclude: props.exclude || []
      }
    }
  }),
  props: ({ data }) => ({ ...data }),
});

export default withPost(ArchiveContainer);
