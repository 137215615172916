import React from "react";
import PropTypes from "prop-types";
import { SectionFull } from "@website2018/da-dobsonville";
import FormContainer from "../../containers/Data/FormContainer"
import FormBuilderContainer from "../../containers/Data/FormBuilderContainer"

class Champ extends React.Component {
  render() {
    const { formId, pb, mb } = this.props;
    return (
      <FormContainer
        formId={formId}
        key={formId}
        render={(form, onSubmit, meta) => (
          <SectionFull
            bgColor="light"
            pt="small"
            pb={pb}
            mb={mb}
            title={form.title}
            center
          >
            <div className="col-md-8 m-auto">
              <FormBuilderContainer
                meta={meta}
                buttonClass="col-lg-6 mt-lg-4 m-auto"
                inputClass="col-md-6"
                form={form}
                onSubmit={onSubmit}
              />
            </div>
          </SectionFull>
        )}
      />
    );
  }
}

Champ.propTypes = {
  children: PropTypes.any
};

Champ.defaultProps = {
  children: null,
  pb: "small",
  mb: null
};

export default Champ;
