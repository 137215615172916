import React from 'react'

import Base from '../People/Base'
import Provincial from '../People/ProvincialTemplate'
import Mpls from '../People/MplsTemplate'
import WCouncillor from '../People/WCTemplate'

const PageTemplate = (props) => {
  const { page: { slug } } = props;
  switch(slug) {
    case 'provincial-leaders':
      return <Provincial {...props} />
    case 'mpls':
      return <Mpls {...props} />
    case 'ward-councillors-map':
    case 'ward-councillors':
      return <WCouncillor {...props} />
    case 'our-people':
    default:
      return <Base {...props} />
  }
}



export default PageTemplate
