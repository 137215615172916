import React from "react";
import { Copy, Label } from "@website2018/da-dobsonville";

const RadioField = ({
  id,
  className,
  error,
  options,
  label,
  type,
  description,
  inputOptions = {}
}) => {
  return (
    <div className={`${className} form-group`}>
      {label ? (
        <Label className="form-check-label" for={id}>
          {label}
        </Label>
      ) : null}
      {options.map((option, index) => (
        <div key={option.value} className="form-check">
          <Label className="form-check-label" forHtml={option.value}>
            <input
              className="form-check-input"
              name={id}
              type={type}
              {...inputOptions}
              value={option.value}
            />

            {option.label}
          </Label>
        </div>
      ))}
      {error && (
        <Copy mb="0" color="red" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <small>
          <em>{description}</em>
        </small>
      ) : null}
    </div>
  );
};

RadioField.defaultProps = {
  className: "col-md-12"
};

export default RadioField;
