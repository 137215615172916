import React from 'react'
import { Border, Sticker } from '@website2018/da-dobsonville';

const Stickers = ({ items }) => {

  if (!items.length) return null;

  return (
    <div className={"row"}>
      {
        items.map((s,index) => (
          <div className={"col-md-4"}>
            <Sticker
              key={`sticker-${index}`}
              title={s.title || null}
              content={s.text || null }
              imageUrl={s.background.url || null}
              icon={s.icon || null}
              color={s.color || null}
            />
          </div>
        ))
      }
      <Border
        mt={"none"}
        mb={"large"}
        color={"whiteO"}
      />
    </div>
  )
}

export default Stickers;
