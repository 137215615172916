import React, { Fragment } from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { noComma, checkLink, copyHash } from '../../common/utils'
import { Ward } from '@website2018/sc-component';
import { Aside, Border, Copy, CardContact, ButtonTest, Heading, HeadingLines, Hero, Icon, Loading, SectionFull, Sidebar, variables } from '@website2018/da-dobsonville';
import { StyleSheet, css } from "aphrodite";

import Asides from '../Asides/Asides';
import ListWrapper from '../Nav/List';
import ListItem from '../Nav/ListItem';
import Spacing from '../Spacing/Spacing';
import ContactInfo from '../../components/Nav/ContactInfo';

import FormContainer from "../../containers/Data/FormContainer"
import FormBuilderContainer from "../../containers/Data/FormBuilderContainer"

import { Link } from 'react-router-dom';

class ContactTemplate extends React.Component {

  state = {
    selected: null
  }

  getOption(option) {
    if (option.type === 'form') {
      return <div>
        <Heading level={6} mt="small" mb="small" color={"blue"}>{ option.title }</Heading>
        <FormContainer
          formId={option.form_id}
          render={
            (form, onSubmit, meta) => (
              <FormBuilderContainer inputClass="col-md-12" form={form}/>
            )
          }
        />
      </div>
    }

    return <div>
      <Heading level={6} mt="small" mb="small" color={"blue"}>{ option.title }</Heading>
      <Copy>{ option.content }</Copy>
      {
        option.button_link && checkLink(option.button_link) ?
          <Link to={option.button_link}>
            <ButtonTest
              color="green"
              center
            >
              {option.button_label}
            </ButtonTest>
          </Link>
        : <a href={option.button_link} target="_blank" rel="noopener noreferrer">
            <ButtonTest
              color="green"
              center
            >
              {option.button_label}
            </ButtonTest>
          </a>
      }
    </div>


  }
  render() {
    const { fonts, fontWeight, fontStyles, letterSpacing, spacing } = variables;
    const { contact, error, loading, page:p } = this.props;
    const { meta: { contact: { heading, options = [] } } } = contact;

    let selected = null;

    if (this.state.selected) {
      selected = this.state.selected;
    }

    if (loading) return <Loading/>;
    if (error) return <h1>ERROR</h1>;

    const styles = {
      table:{
        fontFamily: fonts.sans,
        marginBottom: spacing.space1,
        ...fontStyles('12px','14px'),
      },
      td:{
        paddingRight: spacing.space2,
        paddingBottom: spacing.space05,
      },
      tdLabel:{
        letterSpacing: letterSpacing.small,
        fontWeight: fontWeight.medium,
        paddingRight: spacing.space2,
        paddingTop: spacing.space05,
        paddingBottom: spacing.space05,
        verticalAlign: 'top',
      }
    };

    const baseStyles = StyleSheet.create({
      table: {
        ...styles.table,
      },
      td: {
        ...styles.td,
      },
      tdLabel: {
        ...styles.tdLabel,
      }
    });

    return ([
      <Hero
        imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
      />,

      <Fragment>
        { p.content.rendered &&
          <SectionFull>
            <div className={"col-lg-8"}>
              <Copy intro html={p.content.rendered} />
            </div>
          </SectionFull>
        }
      </Fragment>,

      <SectionFull
        pt="none"
        pb="none"
        mb={"small"}>
        { contact.contact_details.offices && contact.contact_details.offices.map(r =>
          <div className="col">
            <CardContact title={r.title}>
              { r.contact_information &&
                <table className={css(baseStyles.table)}>
                  { r.contact_information && r.contact_information.map(c =>
                    <tr>
                      { c.label && <td className={css(baseStyles.tdLabel)}><Icon fs="16px" name={c.label} /></td>}
                      { c.detail &&
                        <td className={css(baseStyles.td)}>
                          {  c.link === "no" ? <Copy mb="none" html={c.detail} /> : null }
                          {  c.link === "phone" ? <a href={`tel:${c.detail}`} target="_blank" rel="noopener noreferrer"><Copy mb="none" html={c.detail} /></a> : null }
                          {  c.link === "email" ? <a href={`mailto:${c.detail}`} target="_blank" rel="noopener noreferrer"><Copy mb="none" html={c.detail} /></a> : null }
                          {  c.link === "url" ? <a href={`http://${c.detail}`} target="_blank" rel="noopener noreferrer"><Copy mb="none" html={c.detail} /></a> : null }
                        </td>
                      }
                    </tr>
                  )}
                </table>
              }
            </CardContact>
          </div>
        )}
      </SectionFull>,
      <div id="ward">
        <Ward title={copyHash.wardHeadline} description={copyHash.wardDescription}/>
      </div>,
      <SectionFull
        pt={"large"}
      >
        <div className={"col-md-7"}>
          <HeadingLines lineLeft color={"red"} mb={"large"}>
            <Heading
              color={"red"}
              tracking={"huge"}
              size={"tiny"}
              weight={"light"}
              uppercase
            >
              { "DA in Your Area" }
            </Heading>
          </HeadingLines>
          <ListWrapper>
            { contact.contact_details.regions && contact.contact_details.regions.map(r =>

              <ListItem title={r.region_name} collapsed>
                { r.department && r.department.map(c =>
                  <Spacing bottom size={"space3"}>
                    <Spacing top bottom size={"space2"}>
                      <Copy weight="bold">{c.title}</Copy>
                    </Spacing>
                    <table className={css(baseStyles.table)}>
                      { c.contact_details && c.contact_details.map(d =>
                        <tr>
                          { d.label && <td className={css(baseStyles.tdLabel)}><Icon fs="16px" name={d.label} /></td>}
                          { d.detail &&
                            <td className={css(baseStyles.td)}>
                              {  d.link === "no" ? <Copy mb="none" html={d.detail} /> : null }
                              {  d.link === "phone" ? <a href={`tel:${d.detail}`} target="_blank" rel="noopener noreferrer"><Copy mb="none" html={d.detail} /></a> : null }
                              {  d.link === "email" ? <a href={`mailto:${d.detail}`} target="_blank" rel="noopener noreferrer"><Copy mb="none" html={d.detail} /></a> : null }
                              {  d.link === "url" ? <a href={`http://${d.detail}`} target="_blank" rel="noopener noreferrer"><Copy mb="none" html={d.detail} /></a> : null }
                            </td>
                          }
                        </tr>
                      )}
                    </table>
                  </Spacing>
                )}
              </ListItem>

            )}
          </ListWrapper>
        </div>
        <Sidebar className={"col-md-4 ml-auto"}>
          {
            options.length ?
              <Aside title={heading}>
                <div className="form-group">
                  <select className="form-control" onChange={(e) => this.setState({ selected: e.target.value ? options[e.target.value] : null })}>
                    <option>Select an option</option>
                    {
                      options.map((o, index) => <option value={index} key={`options-help-${index}`}>{ o.title }</option>)
                    }
                  </select>
                </div>
                <Border/>
                {
                  selected ? this.getOption(selected) : null
                }
              </Aside>
            : null
          }

          { p.asides &&
            <Asides asides={p.asides} />
          }
          <ContactInfo title={"Stay in Touch"} />
        </Sidebar>
      </SectionFull>,
    ]);
  }
}

const TEMPLATE_QUERY = gql`
  query getTemplate {
    contact: page(slug: "contact") {
      id
      meta {
        contact {
          heading
          options {
            button_link
            button_label
            form_id
            content
            title
            type
          }
        }
      }
      contact_details {
        offices {
          title
          contact_information {
            label
            detail
            link
          }
        }
        regions {
          region_name
          department {
            title
            contact_details {
              label
              detail
              link
            }
          }
        }
      }
    }
  }
`;

const withTemplate = graphql(TEMPLATE_QUERY, {
  props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(ContactTemplate);
