import React, { Fragment } from "react";
import {
  NavIcon,
  CardHighlight,
  CardPerson,
  MoreLink,
  SectionFull,
  variables
} from "@website2018/da-dobsonville";
import SliderWrapper from "../Slider/Slider";
import Spacing from "../Spacing/Spacing"
import { Link } from "react-router-dom";

const People = ({ highlight, people, title = null }) => {
  //if (!people.length) return null;

  const members = people
    .filter(p => !p.government || !p.government.includes("Mayor"))
    .sort(function(a, b) {
      if (a.lastname < b.lastname) return -1;
      if (a.lastname > b.lastname) return 1;
      return 0;
    });

  let count = 0;
  let section = 0;
  const sections = {};

  members.forEach(m => {
    count += 1;

    if (sections[section] && sections[section].length) {
      sections[section].push(m);
    } else {
      sections[section] = [m];
    }

    if (count >= 1 && count % 6 === 0) {
      section += 1;
    }
  });

  const { spacing, colors } = variables;

  return (
    <Fragment>
      <SectionFull
        pt={"large"}
        color={"blue"}
        bgColor={"light"}
        mb={"none"}
        title={title}
      >
        {highlight && highlight.people ? (
          <div className={"col-md-12"}>
            {highlight.people.map(person => (
              <CardHighlight
                imageUrl={
                  person.featured_media
                    ? (
                        (person.featured_media.media_details
                          && person.featured_media.media_details
                          && person.featured_media.media_details.sizes.large)
                        ? person.featured_media.media_details.sizes.large.source_url
                        : person.featured_media.source_url
                      )
                    : null
                }
                title={person.title.rendered}
                meta={person.role}
                paragraph={person.excerpt.rendered}
              >
                <Spacing bottom size={spacing.space2}>
                  <Link to={`/people/${person.slug}`}>
                    <MoreLink mb="medium">Read More</MoreLink>
                  </Link>
                </Spacing>
                {person.facebook && (
                  <NavIcon
                    iconType="bootstrap"
                    iconName="facebook"
                    iconColor={colors.socialFacebook}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={person.facebook}
                      title={person.facebook}
                    >
                      {person.title.rendered} on Facebook
                    </a>
                  </NavIcon>
                )}
                {person.twitter && (
                  <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.twitter.com/${person.twitter}`}
                      title={person.twitter}
                    >
                      Follow {person.twitter}
                    </a>
                  </NavIcon>
                )}
              </CardHighlight>
            ))}
          </div>
        ) : null}
      </SectionFull>
      <SectionFull
        bgColor={"light"}
        mb={"none"}
        pt={"none"}
        pb={"large"}
        container={"container"}
      >
        <div className={"col-md-12"}>
          <div className={"row"}>
            {members.length ? (
              <SliderWrapper>
                {Object.keys(sections).map(s => (
                  <div>
                    <div className="row">
                      {sections[s].map(m => (
                        <div className="col-md-6">
                          <CardPerson
                            title={`${m.prefname || m.firstname} ${m.lastname}`}
                            meta={
                              m.portfolio
                                ? m.portfolio
                                : m.government
                                ? m.government.join(", ")
                                : null
                            }
                            tel={m.cellphone}
                            email={m.email}
                            border={false}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </SliderWrapper>
            ) : null}
          </div>
        </div>
      </SectionFull>
    </Fragment>
  );
};

export default People;
