import React from "react";
import {
  Heading,
  Icon,
  Copy,
  SectionFull,
  variables
} from "@website2018/da-dobsonville";
import color from "color";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

/** Convert Hex to RGBA */
const rgba = (hex, opacity) => {
  return color(hex)
    .alpha(opacity)
    .toString()
};
const { colors, spacing } = variables;

const Stickers = ({ section_title, sticker: items, columns, ...restProps }) => {
  if (!items.length) return null;

  return (
    <SectionFull color="red" title={section_title || null}>
      {items.map((s, index) => (
        <div className={`col-md-4 mb-4`}>
          <Sticker
            key={`sticker-${index}`}
            //title={s.title || null}
            content={s.title || null}
            imageUrl={s.background.url || null}
            icon={s.icon || null}
            color={s.color || null}
          />
        </div>
      ))}
    </SectionFull>
  );
};

Stickers.defaultProps = {
  items: null,
  columns: "columns-4"
};

export default Stickers;

const styles = {
  sticker: {
    base: {
      borderRadius: spacing.space05,
      marginBottom: spacing.space2,
      overflow: "hidden",
      height: "100%",
      display: "flex",
      alignItems: "center",
      width: "100%",
      breakInside: "avoid"
    },
    imageUrl: {
      backgroundSize: "cover",
      backgroundPosition: "center center"
    }
  },
  header: {
    base: {
      display: "flex",
      alignItems: "center"
    },
    heading: {
      fontSize: "20px",
      lineHeight: "20px",
      "@media (min-width: 576px)": {
        fontSize: "49px",
        lineHeight: "49px"
      }
    }
  },
  wrapper: {
    base: {
      padding: spacing.space2,
      border: "1px solid",
      height: "100%",
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: "200px",
      "@media (min-width: 576px)": {
        padding: spacing.space4,
        paddingTop: spacing.space3
      }
    }
  },
  color: {
    white: {
      base: {
        backgroundColor: rgba(colors.brandWhite, 1),
        borderColor: colors.borderLight
      },
      imageUrl: {
        backgroundColor: rgba(colors.brandWhite, 0.9),
        borderColor: colors.borderLight
      }
    },
    blue: {
      base: {
        backgroundColor: rgba(colors.brandBlue, 1),
        borderColor: colors.brandBlue
      },
      imageUrl: {
        backgroundColor: rgba(colors.brandBlue, 0.9),
        borderColor: colors.brandBlue
      }
    },
    green: {
      base: {
        backgroundColor: rgba(colors.brandGreen, 1),
        borderColor: colors.brandGreen
      },
      imageUrl: {
        backgroundColor: rgba(colors.brandGreen, 0.9),
        borderColor: colors.brandGreen
      }
    },
    red: {
      base: {
        backgroundColor: rgba(colors.brandRed, 1),
        borderColor: colors.brandRed
      },
      imageUrl: {
        backgroundColor: rgba(colors.brandRed, 0.9),
        borderColor: colors.brandRed
      }
    },
    yellow: {
      base: {
        backgroundColor: rgba(colors.brandYellow, 1),
        borderColor: colors.brandYellow
      },
      imageUrl: {
        backgroundColor: rgba(colors.brandYellow, 0.9),
        borderColor: colors.brandYellow
      }
    }
  }
};

const Sticker = ({ color, imageUrl, title, content, icon }) => {
  const baseStyles = StyleSheet.create({
    sticker: {
      ...styles.sticker.base,
      ...(color && styles.color[color]),
      ...(imageUrl && styles.sticker.imageUrl),
      ...(imageUrl ? { backgroundImage: `url(${imageUrl})` } : null)
    },
    header: {
      ...styles.header.base
    },
    heading: {
      ...styles.header.heading
    },
    wrapper: {
      ...styles.wrapper.base,
      ...(imageUrl && color && styles.color[color].imageUrl),
      ...(!imageUrl && color && styles.color[color].base)
    }
  });

  return (
    <div className={css(baseStyles.sticker)}>
      <div className={css(baseStyles.wrapper)}>
        <div className={css(baseStyles.header)}>
          {icon && (
            <Icon
              ml={spacing.space0}
              mr={spacing.space2}
              color={color === "white" ? colors.brandBlue : colors.brandWhite}
              fs={"49px"}
              name={icon}
            />
          )}
          {title && (
            <Heading
              size={"huge"}
              weight={"regular"}
              color={color === "white" ? "blue" : "white"}
              mb={icon ? "none" : "small"}
              override={styles.header.heading}
            >
              {title}
            </Heading>
          )}
        </div>

        {content && (
          <Copy
            color={color === "white" ? "blue" : "white"}
            weight={title || icon ? "light" : "regular"}
            size={title || icon ? "medium" : "huge"}
            mb={"none"}
            html={content}
          />
        )}
      </div>
    </div>
  );
};

Sticker.defaultProps = {
  imageUrl: null,
  title: null,
  icon: null,
  color: "white",
  content: null
};

Sticker.propTypes = {
  /** Content of Component */
  content: PropTypes.string.isRequired,
  /** Icon for the Title */
  icon: PropTypes.string,
  /** ImageUrl for background effect */
  imageUrl: PropTypes.string,
  /** Title of Sticker Component */
  title: PropTypes.string,
  /** Background Color Sticker Component */
  color: PropTypes.oneOf(["white", "blue", "red", "green", "yellow"])
};
