import React from 'react'
import { variables, Border, Copy, NavIcon, Widget } from '@website2018/da-dobsonville';

const PersonFooter = ({ p }) => {
  const { colors } = variables;
  return (
    <footer>
      {
        p.email &&
          <Widget
            colTitle={"col-md-3"}
            colSection={"col-md-9"}
            widgetTitle={ 'Email' }>
            <div>
              { <Copy><a href={`mailto:${p.email}`}>{p.email}</a></Copy> }
            </div>
          </Widget>
      }
      {
        p.cellphone &&
          <Widget
            colTitle={"col-md-3"}
            colSection={"col-md-9"}
            widgetTitle={ 'Cellphone' }>
            <div>
              { <Copy><a href={`tel:${p.cellphone}`}>{p.cellphone}</a></Copy> }
            </div>
          </Widget>
      }
      {
        p.offices && p.offices.length > 0 && p.offices.map(t => {
          if (t.address) {
            return (
              <Widget
                colTitle={"col-md-3"}
                colSection={"col-md-9"}
                widgetTitle={ 'Office' }>
                <div>
                  { t.description && <Copy>{t.description}</Copy> }
                  { t.address && <Copy>{t.address}</Copy> }
                  { t.phone && <Copy><a href={`tel:${t.phone}`}>{t.phone}</a></Copy> }
                </div>
              </Widget>
            )
          }

          return null;

        })
      }
      {
        p.social && Object.keys(p.social).length > 0 && <Widget
          colTitle={"col-md-3"}
          colSection={"col-md-9"}
          widgetTitle={ 'Social' }>
          <div>
            { p.social && p.social.facebook &&
              <NavIcon iconType="bootstrap" iconName="facebook" iconColor={colors.socialFacebook}><a target="_blank" rel="noopener noreferrer" href={p.social.facebook} >Facebook</a></NavIcon>
            }
            { p.social && p.social.twitter &&
              <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}><a target="_blank" rel="noopener noreferrer" href={`${p.social.twitter}`}>X (Twitter)</a></NavIcon>
            }
            { p.social && p.social.instagram &&
              <NavIcon iconType="bootstrap" iconName="instagram" iconColor={colors.socialInstagram}><a target="_blank" rel="noopener noreferrer" href={p.social.instagram}>Instagram</a></NavIcon>
            }
            { p.social && p.social.linkedin &&
              <NavIcon iconType="bootstrap" iconName="linkedin" iconColor={colors.socialLinkedIn}><a target="_blank" rel="noopener noreferrer" href={p.social.linkedin}>LinkedIn</a></NavIcon>
            }
          </div>
        </Widget>
      }
      <Border mt="none"/>
    </footer>
  )
}

export default PersonFooter;
