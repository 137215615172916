import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { noComma } from "../../common/utils";
import { Hero, Loading } from "@website2018/da-dobsonville";
import Champ from '../Champ/Champ'

import SettingsProvider from '../../containers/Settings/SettingsProvider';

class StraightTalkTemplate extends React.Component {
  render() {
    const { error, loading, page: p } = this.props;

    if (loading) return <Loading />;
    if (error) return <h1>ERROR</h1>;

    return (
      <Fragment>
        <Hero
          mb={"none"}
          imageUrl={
            p.hero && p.hero.background_image
              ? p.hero.background_image.url
              : null
          }
          title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
          bgPosition={
            p.hero && p.hero.focus_point && noComma(p.hero.focus_point)
          }
        />
        <Champ formId={"straighttalk"} mb={"none"} />
      </Fragment>
    );
  }
}

StraightTalkTemplate.defaultProps = {};

StraightTalkTemplate.propTypes = {};

StraightTalkTemplate.contextTypes = {
  settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired
};

export default StraightTalkTemplate;
