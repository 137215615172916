import React from "react";
import { StyleSheet, css } from "aphrodite";
import {
  variables,
  Border,
  ButtonTest,
  Widget,
  NavIcon
} from "@website2018/da-dobsonville";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";

const ArticleFooter = ({ p }) => {
  const styles = StyleSheet.create({
    articleFooter: {},
    articleDetails: {},
    articleShare: {}
  });
  const { colors } = variables;

  let url = window ? window.location.href : null;

  return (
    <footer>
      <section className={css(styles.articleShare)}>
        <Border mt={"large"} mb={"large"} />
        <Widget
          widgetTitle={"Direct Link"}
          widgetTitleColor={"blue"}
          colTitle={"col-md-12"}
          colSection={"col-md-12"}
        >
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="link"
                placeholder={url}
                readonly
              />

              <CopyToClipboard text={url}>
                <ButtonTest
                  customStyles={{ borderRadius: "0 30px 30px 0 !important" }}
                >
                  Copy
                </ButtonTest>
              </CopyToClipboard>
            </div>
          </div>
        </Widget>

        <Border
          mt={"small"}
          mb={"small"}
          borderColor={"none"}
          color={"whiteO"}
        />

        <Widget
          widgetTitle={"Share"}
          widgetTitleColor={"blue"}
          colTitle={"col-md-12"}
          colSection={"col-md-12"}
        >
          <div className="row">
            <div className="col-md-6">
              <FacebookShareButton url={url}>
                <NavIcon iconType="bootstrap" iconName="facebook" iconColor={colors.socialFacebook}>
                  {"Share on Facebook"}
                </NavIcon>
              </FacebookShareButton>
              <WhatsappShareButton url={url}>
                <NavIcon iconType="bootstrap" iconName="whatsapp" iconColor={colors.socialWhatsApp}>
                  {"Share with WhatsApp"}
                </NavIcon>
              </WhatsappShareButton>
              <TwitterShareButton url={url}>
                <NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>
                  {"Share on X (Twitter)"}
                </NavIcon>
              </TwitterShareButton>
            </div>

            <div className="col-md-6">
              <EmailShareButton url={url}>
                <NavIcon iconType="bootstrap" iconName="envelope-fill" iconColor={colors.socialEmail}>
                  {"Send by Email"}
                </NavIcon>
              </EmailShareButton>
              <NavIcon
                onPress={() => window.print()}
                iconType="bootstrap"
                iconName="printer-fill"
                iconColor={colors.brandBlue}
              >
                {"Print"}
              </NavIcon>
            </div>
          </div>
        </Widget>
      </section>
    </footer>
  );
};

export default ArticleFooter;
