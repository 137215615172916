import React, { Fragment } from "react";
import { Copy, Heading } from "@website2018/da-dobsonville";
import FormContainer from "../../containers/Data/FormContainer"
import FormBuilderContainer from "../../containers/Data/FormBuilderContainer"
import ApplicationContainer from "../../containers/Data/ApplicationContainer";

const FormComponent = ({ content, heading, formId, form_type, ...rest }) => {
  const formContainer =
    form_type === "managed" ? (
      <ApplicationContainer formId={formId} {...rest} />
    ) : (
      <FormContainer
        formId={formId}
        render={(form, onSubmit, meta) => (
          <FormBuilderContainer
            meta={meta}
            form={form}
            onSubmit={onSubmit}
            {...rest}
          />
        )}
      />
    );
  return (
    <Fragment>
      {heading ? <Heading mb="medium" color="blue" html={heading} /> : null}
      <Copy html={content} />
      {formContainer}
    </Fragment>
  );
};

FormComponent.defaultProps = {
  form_type: "standard"
};

export default FormComponent;
