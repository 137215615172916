import React from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { groupBy } from 'ramda'

const byCategory = groupBy((policy) => {
  var category = policy.category;

  if (category && category.length) {
    return category[0].name;
  }

  return 'Core'
});

class PoliciesDataContainer extends React.Component {
  render() {
    const { loading, exclude, include, policies, error, group } = this.props;

    if (loading) return null;
    if (error) return <p>An unexpected error has occured</p>;

    let arr = [];

    if (include) {
      arr = policies.filter(item => {
      if (!item.category) return false;
      if (item.category.map(c => c.name).indexOf(include) > -1) {
        return true
      }
      return false;
    })
      
    } else if (exclude) {
      arr = policies.filter(item => {
        if (!item.category) return false;
        if (item.category.map(c => c.name).indexOf(exclude) > -1) {
          return false
        }
        return true;
      })
    } else {
      arr = policies;
    }

    if (group) {
      arr = byCategory(arr);
    }

    return this.props.render(arr, group)
  }
}

PoliciesDataContainer.defaultProps = {
  group: false,
  include: null,
  exclude: null
}

const POLICIES_QUERY = gql`
  query getTemplate {
    policies {
      id
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      download
      category {
        id
        name
      }
    }
  }
`;

const withTemplate = graphql(POLICIES_QUERY, {
  props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(PoliciesDataContainer);
