import React, { Fragment } from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import {
  variables,
  Copy,
  Hero,
  Loading,
  NavIcon,
  NavItem,
  SectionFull,
  Sidebar,
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import Asides from '../Asides/Asides';
import ListWrapper from '../Nav/List';
import ListItem from '../Nav/ListItem';
import Spacing from '../Spacing/Spacing';
import SubMenu from '../Nav/SubMenu';

import PoliciesDataContainer from "../../containers/Data/PoliciesDataContainer";

class PoliciesTemplate extends React.Component {
  render() {
    const { asides, error, loading, page: p } = this.props;
    const { fontWeight, colors } = variables;

    if (loading) return <Loading />;
    if (error) return <h1>ERROR</h1>;

    return [
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu>
          {p.parent && (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.textBase}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.parent.slug}`}>{p.parent.title.rendered}</Link>
            </NavItem>
          )}
          {p.parent.children.map((c) => (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.linkBlue}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.parent.slug}/${c.slug}`}>{c.title.rendered}</Link>
            </NavItem>
          ))}
        </SubMenu>
      </Sticky>,

      <Hero
        imageUrl={p.featured_media ? p.featured_media.source_url : null}
        title={p.title.rendered}
      />,

      <Fragment>
        {p.content.rendered && (
          <SectionFull mt={"none"}>
            <div className={"col-lg-7"}>
              <Copy intro html={p.content.rendered} />
            </div>
          </SectionFull>
        )}
      </Fragment>,

      // Removed from policy page, but policies have not been removed. So keeping this section here to show how the "core" policies are used
      // <SectionFull>
      //   <div className="col-md-12">
      //     <PoliciesDataContainer
      //       group={false}
      //       include={"Core"}
      //       render={(items, groups) =>
      //         <Policies
      //           items={items}
      //           title={p.acf.policy_headline || "We are ready for 2019!"}
      //           grid={"col-md-6"}
      //         />
      //       }
      //     />
      //   </div>
      // </SectionFull>,

      <SectionFull pb="large">
        <div className="col-lg-7">
          <PoliciesDataContainer
            group={true}
            exclude={"Core"}
            render={(items, groups) => (
              <ListWrapper title={null}>
                {Object.keys(items).map((item) => (
                  <ListItem title={item} collapsed>
                    {items[item].map((c) => (
                      <Spacing top bottom size="space2">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link to={`/policy/${c.slug}`}>
                            <Copy hover html={c.title.rendered} />
                          </Link>
                          {c.download && (
                            <a href={c.download} target={"_blank"}>
                              <NavIcon
                                iconColor={colors.brandRed}
                                iconName="download"
                              />
                            </a>
                          )}
                        </div>
                      </Spacing>
                    ))}
                  </ListItem>
                ))}
              </ListWrapper>
            )}
          />
        </div>

        <Sidebar>{asides && <Asides asides={asides} />}</Sidebar>
      </SectionFull>,
    ];
  }
}

const TEMPLATE_QUERY = gql`
  query getTemplate {
    asides(category: "policies") {
      id
      title {
        rendered
      }
      content {
        rendered
      }
      aside_options
    }
  }
`;

const withTemplate = graphql(TEMPLATE_QUERY, {
  props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(PoliciesTemplate);
