import React from "react";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

export default class YouTubeVideo extends React.Component {
  constructor (props) {
    super(props);
    this.playerRef = React.createRef();
  };

  render() {
    return [
      <div className="videoWrapper">
        <LiteYouTubeEmbed id={this.props.videoId} ref={this.playerRef} />
      </div>,
      this.props.render && this.props.render()
    ];
  }
}
