import React from "react";
import {
  Aside,
  AsideOverlay,
  Copy,
  Heading,
  AsideSmall,
  ButtonTest,
  MoreLink,
  SectionFull,
  CardHighlight
} from "@website2018/da-dobsonville";
import DonorForm from "./DonorForm";

import ListWrapper from "../Nav/List";
import ListItem from "../Nav/ListItem";
import Spacing from "../Spacing/Spacing";

import FormContainer from "../../containers/Data/FormContainer"
import FormBuilderContainer from "../../containers/Data/FormBuilderContainer"
import NavigationContainer from "../../containers/Nav/NavigationContainer"
import TrendingContainer from "../../containers/Content/TrendingContainer";

import { Link } from "react-router-dom";

const Asides = props => {
  return (
    <aside>
      {props.asides.map((a, index) => (
        <AsideSingle
          key={`${index}-asidesingle`}
          {...props}
          type={a.aside_options.type}
          imageUrl={(a.aside_options.image.sizes && a.aside_options.image.sizes.medium_large) || a.aside_options.image.url}
          {...a}
        />
      ))}
    </aside>
  );
};

Asides.defaultProps = {
  fullWidth: false,
  asides: [],
  type: "Aside"
};
export default Asides;

const checkLink = urlString => {
  var pat = /^https?:\/\//i;
  return !pat.test(urlString);
};

const AsideSingle = props => {
  const { type, aside_options, fullWidth, page } = props;
  
  if (fullWidth) {
    return (
      <SectionFull
        mb="none"
        pt="large"
        pb="large"
        bgColor="light"
        key={props.id}
      >
        <div className="col-md-12">
          <CardHighlight
            mb="none"
            title={
              props.aside_options.headline
                ? props.aside_options.headline
                : props.title.rendered
            }
            paragraph={props.content.rendered}
            imageUrl={props.imageUrl}
            bgColor="blue"
          >
            {props.aside_options.aside.url ? (
              <ButtonTest href={props.aside_options.aside.url} color="green">
                {props.aside_options.aside.button_label}
              </ButtonTest>
            ) : null}
          </CardHighlight>
        </div>
      </SectionFull>
    );
  }

  switch (type) {
    case "Aside":
      return (
        <Aside
          key={props.id}
          id={props.id}
          imageUrl={props.imageUrl}
          meta={props.aside_options.aside.meta}
          title={
            props.aside_options.headline
              ? props.aside_options.headline
              : props.title.rendered
          }
          paragraph={props.content.rendered}
          url={props.aside_options.aside.url}
          cta={props.aside_options.aside.button_label}
          vertical
        >
          {aside_options.aside.add_donate_form === true ? (
            <DonorForm {...aside_options.aside} />
          ) : null}
        </Aside>
      );
    case "AsideOverlay":
      return (
        <React.Fragment>
        <div className="visible-sm">
              <AsideSmall
                key={props.id}
                id={props.id}
                imageUrl={props.imageUrl}
                title={
                  props.aside_options.headline
                    ? props.aside_options.headline
                    : props.title.rendered
                }
                url={props.aside_options.asidesmall.url}
                cta={props.aside_options.asidesmall.button_label}
              >
                {checkLink(props.aside_options.asideoverlay.url) ? (
                  <Link to={props.aside_options.asideoverlay.url}>
                    <MoreLink>
                      {props.aside_options.asideoverlay.button_label}
                    </MoreLink>
                  </Link>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.aside_options.asideoverlay.url}
                  >
                    <MoreLink>
                      {props.aside_options.asideoverlay.button_label}
                    </MoreLink>
                  </a>
                )}
              </AsideSmall>
            </div>
            <div className="hidden-sm">
              <AsideOverlay
                key={props.id}
                id={props.id}
                color={props.aside_options.asideoverlay.color}
                imageUrl={props.imageUrl}
                title={
                  props.aside_options.headline
                    ? props.aside_options.headline
                    : props.title.rendered
                }
                paragraph={props.content.rendered}
              >
                {checkLink(props.aside_options.asideoverlay.url) ? (
                  <Link to={props.aside_options.asideoverlay.url}>
                    <ButtonTest color="white" center>
                      {props.aside_options.asideoverlay.button_label}
                    </ButtonTest>
                  </Link>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.aside_options.asideoverlay.url}
                  >
                    <ButtonTest color="white" center>
                      {props.aside_options.asideoverlay.button_label}
                    </ButtonTest>
                  </a>
                )}
              </AsideOverlay>
            </div>
            </React.Fragment>
      );
    case "AsideSmall":
      return (
        <AsideSmall
          key={props.id}
          id={props.id}
          imageUrl={props.imageUrl}
          title={
            props.aside_options.headline
              ? props.aside_options.headline
              : props.title.rendered
          }
          url={props.aside_options.asidesmall.url}
          cta={props.aside_options.asidesmall.button_label}
        >
          {checkLink(props.aside_options.asidesmall.url) ? (
            <Link to={props.aside_options.asidesmall.url}>
              <MoreLink>{props.aside_options.asidesmall.button_label}</MoreLink>
            </Link>
          ) : (
            <a href={props.aside_options.asidesmall.url}>
              <MoreLink>{props.aside_options.asidesmall.button_label}</MoreLink>
            </a>
          )}
        </AsideSmall>
      );
    case "Form":
      return (
        <Aside
          key={props.id}
          id={props.id}
          imageUrl={props.imageUrl}
          meta={props.aside_options.aside.meta}
          title={
            props.aside_options.headline
              ? props.aside_options.headline
              : props.title.rendered
          }
          paragraph={props.content.rendered}
          url={props.aside_options.url}
          cta={props.aside_options.aside.button_label}
          vertical
        >
          {props.aside_options.form &&
            props.aside_options.form.form_type && (
              <FormContainer
                formId={props.aside_options.form.form_type}
                render={(form, onSubmit, meta) => (
                  <FormBuilderContainer
                    meta={meta}
                    form={form}
                    page={props.page}
                    onSubmit={onSubmit}
                    inputClass={props.inputClass}
                  />
                )}
              />
            )}
        </Aside>
      );
    case "Menu":
      return (
        <NavigationContainer
          menuId={props.aside_options.menu}
          render={menu => {
            return (
              <ListWrapper key={props.id} title={props.aside_options.headline}>
                {menu.items.map((item, index) => (
                  <Link
                    key={`${index}-${props.id}`}
                    to={`/${item.object_slug || item.url}`}
                  >
                    <ListItem title={item.title} collapsed />
                  </Link>
                ))}
              </ListWrapper>
            );
          }}
        />
      );
    case "Trending":
      return <TrendingContainer title={props.aside_options.headline
        ? props.aside_options.headline
        : "Trending Article"} term="trending" taxonomy="tag" limit="5"/>
    default:
      return null;
  }
};
