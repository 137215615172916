import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from '@website2018/da-dobsonville';

class PubRepsContainer extends React.Component {
  render() {
    const { loading, error, publicreps } = this.props;

    if (loading) return <Loading/>;
    if (error) return <p>An unexpected error has occured</p>;

    return this.props.render(publicreps);
  }
}

const PEOPLE_QUERY = gql`
  query getPeople($input: PublicRepInput!, $type: String!) {
    publicreps(input:$input) {
      title
      lastname
      role(type:$type)
      email
      cellphone
      government
      provincial
      federal
      offices {
        address
        description
        phone
        primary
      }
      social {
        website
        facebook
        twitter
        instagram
        linkedin
      }
    }
  }
`;

const withPeople = graphql(PEOPLE_QUERY, {
  options: (props) => {
    return ({
      variables: { input: props.query, type: props.type }
    })
  },
  props: ({ data, post }) => ({ ...data }),
});

PubRepsContainer = withPeople(PubRepsContainer);

export default PubRepsContainer;
