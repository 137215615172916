import React from 'react'
import { SectionFull } from '@website2018/da-dobsonville';
import Asides from '../Asides/Asides';

const AsidesGrid = (props) => {

  if (!props.asidesGrid.length) return null;

  const one = props.asidesGrid[0] || null
  const two = props.asidesGrid[1] || null
  const three = props.asidesGrid[2] || null
  const four = props.asidesGrid[3] || null

  return ([
    <SectionFull mt={"none"}>
      <div className={"col-md-7"}>
        <Asides asides={[one]}/>
        {four && <Asides asides={[four]}/>}
      </div>

      <div className={"col-md-5"}>
        <Asides asides={[two]} height={'346px'}/>
        <Asides asides={[three]} inputClass={"col-md-6"}/>
      </div>
    </SectionFull>
  ])
}

AsidesGrid.defaultProps = {
  asides: [],
}
export default AsidesGrid
