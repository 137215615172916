import React from "react";

import Page from './Page';
import ContactTemplate from './ContactTemplate';
import DonateTemplate from './DonateTemplate';
import GetInvolvedTemplate from './GetInvolvedTemplate';
import NewsroomTemplate from './NewsroomTemplate';
import HistoryTemplate from './HistoryTemplate';
import PoliciesTemplate from './PoliciesTemplate';
import SuccessTemplate from './SuccessTemplate';
import WTDTemplate from './WTDTemplate';
import WWGTemplate from './WWGTemplate';
import StraightTalkTemplate from './StraightTalkTemplate';

import SignupContainer from "../../containers/Content/SignupContainer";
import ApplicationContainer from "../../containers/Data/ApplicationContainer";

const PageTemplate = props => {
  const {
    page: { slug, template }
  } = props;

  if (slug === "where-we-govern") {
    return <WWGTemplate {...props} />;
  }

  if (slug === "yes") {
    return <SignupContainer {...props} />;
  }

  if (slug === "straight-talk") {
    return <StraightTalkTemplate {...props} />;
  }

  switch (template) {
    case "template-form.php":
      return <ApplicationContainer {...props} />;
    case "template-getinvolved.php":
      return <GetInvolvedTemplate {...props} />;
    case "template-history.php":
      return <HistoryTemplate {...props} />;
    case "template-wtd.php":
      return <WTDTemplate {...props} />;
    case "template-policies.php":
      return <PoliciesTemplate {...props} />;
    case "template-newsroom.php":
      return <NewsroomTemplate {...props} />;
    case "template-donate.php":
      return <DonateTemplate {...props} />;
    case "template-contact.php":
      return <ContactTemplate {...props} />;
    case "template-success.php":
      return <SuccessTemplate {...props} />;
    default:
      return <Page {...props} />;
  }
};

export default PageTemplate;
