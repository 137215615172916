import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from "@website2018/da-dobsonville";

import CampaignTemplate from '../../components/Campaigns/CampaignTemplate'
import CampaignTeamTemplate from '../../components/Campaigns/CampaignTeamTemplate'
import CampaignOneTemplate from '../../components/Campaigns/CampaignOneTemplate'
import CompetitionTemplate from '../../components/Campaigns/CompetitionTemplate'
import CampaignsContainer from './CampaignsContainer'

import { Route, Switch, Redirect } from "react-router-dom";

const PageRouter = props => {
  return (
    <Switch>
      <Route exact path="/campaigns/:slug" component={CampaignContainer} />
      <Route exact path="/campaigns" component={CampaignsContainer} />
    </Switch>
  );
};

class CampaignContainer extends React.Component {
  render() {
    const { error, loading, campaign } = this.props;

    if (loading) return <Loading />;
    if (error || !campaign) return <Redirect to={"/not-found"} />;
    if (campaign.slug === "team-one-south-africa") {
      return <CampaignTeamTemplate {...this.props} />;
    }
    if (campaign.slug === "one-south-africa-for-all") {
      return <CampaignOneTemplate {...this.props} />;
    }
    if (campaign.slug === 'powertotheregistered') {
      return <CompetitionTemplate {...this.props} />;
    }
    return <CampaignTemplate {...this.props} />;
  }
}

const CAMPAIGN_QUERY = gql`
  query getCampaign($slug: String!) {
    campaign(slug: $slug) {
      id
      slug
      seo
      title {
        rendered
      }
      excerpt {
        rendered
      }
      content {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      acf
      hero {
        headline
        description
        cta_type
        cta_group {
          title
          link_label
          link_url
        }
        background_image {
          url
        }
        focus_point
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      posts {
        id
        date
        slug
        formattedDate
        title {
          rendered
        }
        category {
          id
          name
        }
      }
      onesouthafricaforall
    }
  }
`;

const withCampaign = graphql(CAMPAIGN_QUERY, {
  options: props => {
    return {
      variables: {
        slug: props.match.params.slug
      }
    };
  },
  props: ({ data, post }) => ({ ...data })
});

CampaignContainer = withCampaign(CampaignContainer);

export default PageRouter;
