import React, { Component } from 'react';
import { StyleSheet, css } from "aphrodite";
import { Heading, variables } from "@website2018/da-dobsonville";

class List extends Component {
  render() {
    const { spacing } = variables;
    const { title, children } = this.props;
    const styles = StyleSheet.create({
      list:{
        marginBottom: spacing.space2,
        "@media (min-width: 576px)": {
          marginBottom: spacing.space5,
        }
      },
      listItems:{
        padding: 0,
      },
    });

    return (
      <div className={css(styles.list)}>
        { title && <Heading color={"red"} size={"tiny"} tracking={"huge"} weight={"regular"} mb="small" uppercase>{title}</Heading> }
        { children &&
          <ul className={css(styles.listItems)}>
            { children }
          </ul>
        }
      </div>
    )
  }
}

export default List;
