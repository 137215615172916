import React from 'react'
import Media from 'react-media'
import { connectHits } from 'react-instantsearch/connectors';
import { variables, Copy, CardNews, CardNewsMobile, Heading, MoreLink } from '@website2018/da-dobsonville';
import { css, StyleSheet } from 'aphrodite';
import { getPostTypeLabel, getItemLink } from '../../common/utils';
import { Link } from 'react-router-dom';

const { spacing } = variables;

const styles = {
  container: {
    base:{
      display: "inline-block",
      marginBottom: spacing.space4,
      ':hover': {
        opacity: 0.8
      }
    },
    full: {
      marginBottom: spacing.space6,
    }
  },
  header: {
    marginBottom: spacing.space2,
  },
  img: {
    width: '100%',
  },
};

const SearchResults = ({ hits = [], type }) => {

  const baseStyles = StyleSheet.create({
    container: {
      ...styles.container.base,
    },
    header: {
      ...styles.header,
    },
  });

  if (type === 'posts') {
    return (
      <div className="row">
        {
          hits.map((p) =>
            <Link to={getItemLink(p)} className={"col-md-12"} key={p.id}>
              <Media query="(max-width: 767px)">
                {matches => matches ? (
                  <CardNewsMobile
                    imageUrl={p.images.thumbnail ? p.images.thumbnail : null}
                    title={p.post_title}
                    text={p.post_excerpt || p._snippetResult.content.value}
                    meta={p.taxonomies.category && p.taxonomies.category.length ? `${p.post_date_formatted} in ${p.taxonomies.category[0]}` : `${p.post_date_formatted}`}>
                    <MoreLink>Read More</MoreLink>
                  </CardNewsMobile>
                ) : (
                  <CardNews
                    full
                    vertical
                    imageUrl={p.images['card-desktop-large'] ? p.images['card-desktop-large'] : null}
                    title={p.post_title}
                    text={p.post_excerpt || p._snippetResult.content.value}
                    meta={p.taxonomies.category && p.taxonomies.category.length ? `${p.post_date_formatted} in ${p.taxonomies.category[0]}` : `${p.post_date_formatted}`}>
                    <MoreLink>Read More</MoreLink>
                  </CardNews>
                )}
              </Media>
            </Link>
          )
        }
      </div>
    )
  }
    
  return (
    <div className="row">
      {
        hits.map((p) =>
          <Link to={getItemLink(p)} className={"col-md-12"}>
            <article className={`${css(baseStyles.container)}`}>
              <header className={css(baseStyles.header)}>
                <Heading
                  level={6}
                  color={"blue"}
                  size={"tiny"}
                  weight={"regular"}
                  mb={"tiny"} 
                  tracking={"small"}
                  uppercase
                >
                  { getPostTypeLabel(p.post_type_label) }
                </Heading>
                <Heading
                  level={3}
                  hover
                  size={"medium"}
                  mb={"tiny"}
                  tracking={"none"}
                >
                  { p.post_title}
                </Heading>
              </header>
              <Copy html={p.post_excerpt || p._snippetResult.content.value} />
              <MoreLink>View More</MoreLink>
            </article>
          </Link>
        )
      }
    </div>
  )
}

export default connectHits(SearchResults);