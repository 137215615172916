import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from "@website2018/da-dobsonville";
import { Route, Switch, Redirect } from "react-router-dom";

import MetaHeader from '../../components/Meta/MetaHeader';
import PageTemplate from '../../components/Page/PageTemplate';

const PageRouter = props => {
  return (
    <Switch>
      <Route exact path="/:parent/:slug" component={PageContainer} />
      <Route exact path="/:slug" component={PageContainer} />
    </Switch>
  );
};

class PageContainer extends React.Component {
  render() {
    const { error, loading, page: p } = this.props;

    if (loading) return <Loading />;
    if (error || !p) return <Redirect to={"/not-found"} />;

    return [<MetaHeader p={p} />, <PageTemplate {...this.props} />];
  }
}

const PAGE_QUERY = gql`
  query getPage($slug: String!) {
    posts(first: 13, taxonomy: "category", term: "news") {
      id
      date
      slug
      formattedDate
      title {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
          sizes {
            medium { source_url }
          }
        }
      }
    }
    page(slug: $slug) {
      id
      slug
      seo
      template
      acf
      title {
        rendered
      }
      content {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      hero {
        headline
        description
        cta_type
        cta_group {
          title
          link_label
          link_url
        }
        background_image {
          url
        }
        focus_point
        countdown {
          headline
          date
        }
      }
      testimonials {
        section_heading
        testimonial {
          headline
          attribution
          photo {
            url
            sizes {
              medium
            }
          }
          text
        }
      }
      stickers {
        title
        icon
        background {
          url
        }
        color
        text
      }
      list {
        hasList
        section_heading
        collapsible
        list_items {
          title
          text
          image {
            url
            sizes { medium_large }
          }
          icon
        }
      }
      parent {
        slug
        title {
          rendered
        }
        children {
          slug
          title {
            rendered
          }
        }
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      asides_grid {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      children {
        id
        slug
        title {
          rendered
        }
        content {
          rendered
        }
      }
      timeline {
        date
        headline
        content
        image {
          url
        }
      }
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: props.match.params.slug
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

PageContainer = withPage(PageContainer);

export default PageRouter;
