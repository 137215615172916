import React from 'react'
import { Copy, Hero, SectionFull } from '@website2018/da-dobsonville';
import { Helmet } from "react-helmet-async";

const NotFound = (props) => {
  return ([
    <Helmet
      defaultTitle="Page Not Found"
      htmlAttributes={{"lang": "en"}}
    />,
    <Hero
      title={'Page Not Found'}
      mb={"none"}
    />,
    <SectionFull mt={"none"}>
      <div className={"col-lg-7"}>
        <article className="entry">
          <Copy intro>The page you are trying to reach does not exist, or has been moved. Please use the menus or the search box to find what you are looking for.</Copy>
        </article>
      </div>
    </SectionFull>
  ])
}

export default NotFound;
