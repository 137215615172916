import React from "react";
import { Link } from "react-router-dom";
//import { Space, ButtonText, Button } from "@singita/components";
import { ButtonTest } from "@website2018/da-dobsonville";
import Spacing from "../Spacing/Spacing";

const checkLink = (link) => {
  var pattern = /^((http|https|ftp):\/\/)/;

  return pattern.test(link);
};

export const AppLink = ({ renderInner, title = "View More", link, type }) => {
  const buttonType =
    type === "Button" ? (
      <ButtonTest size="small">{title}</ButtonTest>
    ) : (
      <ButtonTest>{title}</ButtonTest>
    );

  const inner = renderInner ? (
    renderInner()
  ) : (
    <Spacing bottom size={"space05"}>
      {buttonType}
    </Spacing>
  );

  return checkLink(link) ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {inner}
    </a>
  ) : (
    <Link to={link}>{inner}</Link>
  );
};
