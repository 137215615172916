import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import FormContainer from './FormContainer';
import FormBuilderContainer from './FormBuilderContainer';
import { path } from "ramda";
import { withRouter } from "react-router-dom";
import { AuthComponent } from "../Auth/AuthComponent";

const mapper = (data, map) => {
  const newData = {};
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  Object.keys(map).forEach(key => {
    const property = map[key];
    const obj = getNestedObject(data, property);
    if (obj) {
      newData[key] = obj;
    }
  });

  return newData;
};

const mapUserToForm = (user, form) => {
  const forms = {
    daso: {
      idnumber: ["personId"],
      submittedByIdNumber: ["personId"],
      submittedByName: ["firstNamesText"],
      submittedBySurname: ["surnameText"]
    }
  };

  if (!forms[form]) {
    return user;
  }

  return {
    ...user,
    ...mapper(user, forms[form])
  };
};

class ApplicationContainer extends React.Component {
  state = {
    application: {
      homeLocationCountry: "ZA",
      physicalLocationCountry: "ZA",
      uploads: {}
    },
    errors: [],
    sent: false,
    loading: true
  };

  updateApplicationForm = e => {
    if (["checkbox", "radio"].includes(e.target.type)) {
      const newValue = e.target.value;

      this.setState({
        application: {
          ...this.state.application,
          [e.target.name]: newValue
        }
      });
    } else {
      this.setState({
        application: {
          ...this.state.application,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  updateApplicationFiles = ([file], field) => {
    const { FileUpload } = this.props;

    if (!!file) {
      FileUpload({
        variables: { file }
      })
        .then(({ data: { singleUpload } }) => {
          this.setState({
            errors: {
              [field]: null
            },
            application: {
              ...this.state.application,
              uploads: {
                ...this.state.application.uploads,
                [field]: {
                  ...singleUpload
                }
              }
            }
          });
        })
        .catch(err => {
          let errors = err.graphQLErrors
            ? path(["graphQLErrors", 0, "message"])(err)
            : ["Unexpected error."];

          this.setState(state => ({
            errors: {
              ...state.errors,
              [field]: errors
            }
          }));
        });
    } else {
      this.setState({
        application: {
          ...this.state.application,
          uploads: {
            ...this.state.application.uploads,
            [field]: null
          }
        }
      });
    }
  };

  onSubmit = async (values, errors) => {
    const {
      admin_email,
      confirmation_url = "/thank-you-for-applying"
    } = this.props;

    try {
      await this.props.submitForm({
        variables: {
          input: {
            form: this.props.formId,
            fields: {
              ...values,
              adminEmail: admin_email || "sergio@plusplusminus.co.za",
              uploads: this.state.application.uploads
            }
          }
        }
      });

      this.props.history.push(confirmation_url);
    } catch (err) {
      alert(err.message);
    }
  };

  render() {
    const { formId, isAuthed = false } = this.props;

    return (
      <FormContainer
        formId={formId}
        render={form => (
          <AuthComponent isAuthed={isAuthed}>
            {user => {
              return (
                <FormBuilderContainer
                  form={form}
                  handleSubmit={this.onSubmit}
                  inputOptions={{
                    onChange: e => this.updateApplicationForm(e)
                  }}
                  fileOptions={{
                    onChange: this.updateApplicationFiles,
                    uploads: this.state.application.uploads,
                    errors: this.state.errors
                  }}
                  values={{
                    ...mapUserToForm(user, formId),
                    ...this.state.application
                  }}
                  page={{}}
                  readOnly={{}}
                />
              );
            }}
          </AuthComponent>
        )}
      />
    );
  }
}

const UPLOAD_FILE = gql`
  mutation($file: Upload!) {
    singleUpload(file: $file) {
      _id
      filename
    }
  }
`;

const withUpload = graphql(UPLOAD_FILE, { name: "FileUpload" });

const ADD_SUBMISSION_MUTATION = gql`
  mutation submitForm($input: SubmissionInput!) {
    submitForm(input: $input)
  }
`;

const withSubmission = graphql(ADD_SUBMISSION_MUTATION, { name: "submitForm" });

export default withRouter(withSubmission(withUpload(ApplicationContainer)));
