import React from 'react'
import { variables, ArticleHeader, AsideList, Border, CardNews, Copy, NavItem, SectionFull, Sidebar } from '@website2018/da-dobsonville';
import { Link } from 'react-router-dom';
import Media from "react-media";
import ArticleFooter from './ArticleFooter'
import { getTaxLink, dateRegex } from '../../common/utils'
import ArchiveContainer from '../../containers/Widgets/ArchiveContainer';
import SubMenu from '../Nav/SubMenu';
import NoSSR from '../Nav/NoSSR';
import Sticky from 'react-stickynode';
import EmbedContainer from 'react-oembed-container';

const Article = (props) => {
  const { post:p, taxonomy } = props;
  const { fontWeight, colors } = variables;

  return ([
    <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
      <SubMenu>
        <NavItem
          fontWeight={fontWeight.medium}
          color={colors.textBase}
          fs={"13px"}
          lh={"16px"}>
            <Link to={`/newsroom`}>Newsroom</Link>
        </NavItem>
        {
          taxonomy.filter(c => c.name !== 'News').map(c =>
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.linkBlue}
              fs={"13px"}
              lh={"16px"}>
              <Link to={getTaxLink(c.taxonomy, c.name)}>{ c.name }</Link>
            </NavItem>
          )
        }
      </SubMenu>
    </Sticky>,

    <SectionFull mt={"medium"}>

      <div className={"col-lg-8"}>

        <ArticleHeader
          subHeading={p.province && p.province.length ? `${p.province.map(p => p.name).join(' | ')}` : null }
          title={p.title.rendered}
          attribution={p.meta && p.meta.person && p.meta.person.hasMember ? `Issued by ${p.meta.person.member} – ${p.meta.person.role}` : null}
          meta={p.category && p.category.length ? `${p.formattedDate} in ${p.category[0].name}` : `${p.formattedDate}`}
        />
        
        <EmbedContainer className="entry" markup={p.content.rendered}>
          <Copy intro html={p.content.rendered} />
        </EmbedContainer>

        <Media query="(min-width: 576px)">
          { matches => matches ? (
            <Border
              mt={"large"}
              mb={"large"}
            />
          ) : (
            <Border
              mt={"small"}
              mb={"medium"}
            />
        )}
      </Media>

        <NoSSR><ArticleFooter p={p} /></NoSSR>

      </div>

      <Sidebar col="col-lg-3 ml-auto">
        <ArchiveContainer
          limit={5}
          exclude={[p.id]}
          render={
            (archive) => (
              <div>
                {
                  archive.length
                  ?
                    (
                      <AsideList
                        title={"Latest News"}
                      >
                        {
                          archive.map(p => {
                            const [, year, month] = dateRegex.exec(p.date);
                            const imageUrl = p.featured_media
                              ? ((p.featured_media.media_details
                                    && p.featured_media.media_details.sizes
                                    && p.featured_media.media_details.sizes.medium)
                                ? p.featured_media.media_details.sizes.medium.source_url
                                : p.featured_media.source_url)
                              : null
                            return (
                              <Link to={`/${year}/${month}/${p.slug}`} key={p.id}>
                                <Media
                                  query="(max-width: 767px)"
                                  render={() =>
                                    <CardNews
                                      imageUrl={imageUrl}
                                      title={p.title.rendered}
                                      meta={p.category.length ? `${p.formattedDate} in ${p.category[0].name}` : `${p.formattedDate}`}
                                    />
                                  }
                                />
                                <Media
                                  query="(min-width: 768px) and (max-width: 992px)"
                                  render={() =>
                                    <CardNews
                                      imageUrl={imageUrl}
                                      title={p.title.rendered}
                                      meta={p.category.length ? `${p.formattedDate} in ${p.category[0].name}` : `${p.formattedDate}`}
                                    />
                                  }
                                />
                                <Media
                                  query="(min-width: 993px)"
                                  render={() =>
                                    <CardNews
                                      vertical
                                      imageUrl={imageUrl}
                                      title={p.title.rendered}
                                      meta={p.category.length ? `${p.formattedDate} in ${p.category[0].name}` : `${p.formattedDate}`}
                                    />
                                  }
                                />
                              </Link>
                            )
                          })
                        }
                      </AsideList>
                    )
                  : null
                }
              </div>
            )
          }
        />
      </Sidebar>
    </SectionFull>,
  ])
}

export default Article;
