import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  HeadingLines,
  Heading,
  ButtonTest,
  variables,
} from "@website2018/da-dobsonville";
import moment from "moment";
import pad from "left-pad";
import { StyleSheet, css } from "aphrodite";

import Spacing from "../Spacing/Spacing";
import Media from "react-media";

const Actions = (props) => {
  if (props.type === "Group" && props.actions.length) {
    return (
      (
        <Media
          query="(min-width: 992px)"
          render={() => (
            <div className="row justify-content-center">
              <HeadingLines lineLeft lineRight color="white" mb="large">
                <Heading color="white" size="large" align="center">
                  {props.headline}
                </Heading>
              </HeadingLines>
            </div>
          )}
        />
      ),
      (
        <div className="row justify-content-center">
          <Media query="(min-width: 992px)">
            {(matches) =>
              matches ? (
                <Fragment>
                  {props.actions.map((a, index) => (
                    <Action key={`action-${index}`} index={index} {...a} />
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  <Action {...props.actions[0]} />
                </Fragment>
              )
            }
          </Media>
        </div>
      )
    );
  }

  if (props.type === "Countdown") {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <CountdownText title={props.headline} date={props.date} />
          {props.label && (
            <div className="container-fluid">
              <div className="row justify-content-center">
                <a href={props.url} target={props.target}>
                  <ButtonTest color={props.color} size="large">
                    {props.label}
                  </ButtonTest>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (props.type === "Button") {
    return (
      props.label && (
        <div className="container-fluid">
          <div className="row justify-content-center">
            <a href={props.url} target={props.target}>
              <ButtonTest color={props.color} size="large">
                {props.label}
              </ButtonTest>
            </a>
          </div>
        </div>
      )
    );
  }

  return null;
};

Actions.defaultProps = {
  actions: [],
  type: "Group",
};

const Action = ({ title, link_label, link_url, link_color }) => {
  return (
    <div className="col-lg-4 text-center">
      <Spacing bottom size="space4">
        <Heading color="white" size="small" mb="small" align="center">
          {title}
        </Heading>
        <ButtonTest color={link_color} size="small" href={link_url}>
          {link_label}
        </ButtonTest>
      </Spacing>
    </div>
  );
};

class CountdownText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endDate: this.props.date,
      countdown: this.calculateCountdown(this.props.date),
      isExpired: false,
    };
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState({ countdown: date }) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    const now = moment();
    let diff = moment(endDate).diff(now, "seconds");

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  render() {
    const { countdown } = this.state;
    const { title } = this.props;
    const { spacing, colors } = variables;
    const styles = StyleSheet.create({
      wrapper: {
        padding: spacing.space2,
        backgroundColor: colors.bgLightO,
        borderRadius: spacing.space1,
        display: "inline-block",
        textAlign: "center",
        marginBottom: spacing.space2,
        "@media (min-width: 576px)": {
          padding: spacing.space4,
        },
      },
      countdown: {
        display: "inline-flex",
        alignItems: "center",
      },
      unit: {
        paddingLeft: spacing.space2,
        paddingRight: spacing.space2,
        display: "flex-inline",
        flexDirection: "column",
        alignItems: "center",
      },
    });

    return (
      <section className={css(styles.wrapper)}>
        {title && (
          <Heading
            color={"red"}
            size={"tiny"}
            tracking={"huge"}
            weight={"regular"}
            uppercase
            align="center"
            mb="small"
          >
            {title}
          </Heading>
        )}
        <div className={css(styles.countdown)}>
          <div className={css(styles.unit)}>
            <Heading align="center" size="huge" color="blue">
              {pad(countdown.days, 2, 0)}
            </Heading>
            <Heading align="center" size="small" color="base">
              days
            </Heading>
          </div>
          <div className={css(styles.unit)}>
            <Heading align="center" size="huge" color="blue">
              {pad(countdown.hours, 2, 0)}
            </Heading>
            <Heading align="center" size="small" color="base">
              hours
            </Heading>
          </div>
          <div className={css(styles.unit)}>
            <Heading align="center" size="huge" color="blue">
              {pad(countdown.min, 2, 0)}
            </Heading>
            <Heading align="center" size="small" color="base">
              minutes
            </Heading>
          </div>
          <div className={css(styles.unit)}>
            <Heading align="center" size="huge" color="blue">
              {pad(countdown.sec, 2, 0)}
            </Heading>
            <Heading align="center" size="small" color="base">
              seconds
            </Heading>
          </div>
        </div>
      </section>
    );
  }
}

CountdownText.defaultProps = {
  title: null,
};

CountdownText.propTypes = {
  endDate: PropTypes.object,
};

export default Actions;
