import React from 'react'
import { ArticleHeader, Copy, SectionFull } from '@website2018/da-dobsonville';


import JobFooter from './JobFooter'

import { NoSSR } from '../../components'

const Job = (props) => {
  const { job:p } = props;

  return ([
    <SectionFull mt={"medium"}>
      <div className={"col-md-8"}>
        <ArticleHeader
          subHeading={p.meta.job.province && p.meta.job.province.length ? `${p.meta.job.province.map(p => p.name).join(' | ')}` : null }
          title={p.title.rendered}
          attribution={p.meta.job.reference ? `Reference: ${p.meta.job.reference}` : null}
          meta={`Closing Date: ${p.meta.job.date}`}
        />
        <article className="entry">
          <Copy html={p.content.rendered} />
        </article>
        <NoSSR><JobFooter p={p} /></NoSSR>
      </div>
    </SectionFull>
  ])
}

export default Job;
