import React, { Fragment } from "react";

import ContentComponent from './Content';
import FAQComponent from './FAQ';
import GalleryComponent from './Gallery'
import VideoComponent from './Video'
import VideosComponent from './Videos'
import FormComponent from "./Form";
import Stickers from "./Stickers";
import { ItemsComponent } from "./Items";

class Layout extends React.Component {
  getTemplate(layout) {
    switch (layout.acf_fc_layout) {
      case "faq":
        return <FAQComponent {...layout} />;
      case "video":
        return <VideoComponent {...layout} />;
      case "videos":
        return <VideosComponent {...layout} />;
      case "text":
        return <ContentComponent {...layout} />;
      case "gallery":
        return <GalleryComponent {...layout} />;
      case "items":
        return <ItemsComponent {...layout} />;
      case "form":
        return <FormComponent {...layout} />;
      case "stickers": {
        return <Stickers {...layout} />;
      }
      default:
        return null;
    }
  }
  render() {
    const { layout = [] } = this.props;

    return (
      <Fragment>
        {layout.map(l => (
          <div>{this.getTemplate(l)}</div>
        ))}
      </Fragment>
    );
  }
}

export default Layout;
