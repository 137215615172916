import React, { Fragment } from "react";
import {
  CardCampaign,
  Copy,
  Hero,
  SectionFull,
  Sidebar,
  Testimonial
} from "@website2018/da-dobsonville";
import { noComma } from "../../common/utils";
import { Link } from "react-router-dom";

import Asides from '../Asides/Asides'
import MetaHeader from '../Meta/MetaHeader'
import ListWrapper from "../Nav/List"
import ListItem from "../Nav/ListItem"
import Posts from '../Newsroom/Posts'
import SliderWrapper from "../Slider/Slider";
import Stories from "../Stories/Stories";
import Stickers from "./Stickers"
import Videos from "../Videos/Videos"
import People from "./People";

const BaseGovernment = props => {
  const { govern: p, slug } = props;

  return [
    <MetaHeader p={p} />,
    <Hero
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      mb={p.hero && p.hero.background_image ? "large" : "none"}
    />,

    <SectionFull mt={"none"}>
      <div className={"col-md-7"}>
        <SectionFull mt="none" mb="none" container={false} rowClass={false}>
          {p.content.rendered && <Copy intro html={p.content.rendered} />}
        </SectionFull>
        {!p.list.list_items.length && p.stickers.length && (
          <SectionFull
            mt="large"
            color="red"
            container=""
            title={`${p.title.rendered} Success Stories`}
          >
            <div className="col-md-12">
              {p.stickers.length && (
                <Stickers columns={"columns-2"} items={p.stickers} />
              )}
            </div>
          </SectionFull>
        )}
        {p.list.hasList && (
          <SectionFull
            title={p.list.section_heading}
            color={"red"}
            mt="large"
            mb="none"
            container={false}
            rowClass={false}
          >
            <div>
              {p.list.list_items.length &&
                p.list.list_items.map((l, index) => (
                  <CardCampaign
                    key={`${index}-${l.id}`}
                    imageUrl={(l.image.sizes && l.image.sizes.medium_large) || l.image.url}
                    title={l.title}
                    paragraph={l.text}
                  />
                ))}
            </div>
          </SectionFull>
        )}
      </div>
      <Sidebar>
        <ListWrapper title={"Where we Govern"}>
          {p.related.map(r => (
            <ListItem
              key={r.slug}
              link
              title={
                <Link to={`/government/${r.slug}`}>{r.title.rendered}</Link>
              }
            />
          ))}
        </ListWrapper>
        {p.asides && <Asides asides={p.asides} />}
      </Sidebar>
    </SectionFull>,
    <Posts
      mn={true}
      government={p.slug || slug}
      posts={p.posts}
      title={`${p.title.rendered} Newsroom`}
    />,
    <Fragment>
      {p.testimonials.testimonial && p.testimonials.testimonial.length ? (
        <SectionFull
          bgColor={"light"}
          pt={"large"}
          pb={"large"}
          mb={"none"}
          container={"container"}
        >
          <SliderWrapper>
            {p.testimonials.testimonial.map(t => (
              <div>
                <Testimonial
                  headline={t.headline}
                  text={t.text}
                  imageUrl={(t.photo.sizes && t.photo.sizes.medium) || t.photo.url}
                  meta={t.attribution}
                />
              </div>
            ))}
          </SliderWrapper>
        </SectionFull>
      ) : null}
    </Fragment>,
    <Fragment>
      {p.list.list_items.length && p.stickers.length ? (
        <SectionFull
          mt="large"
          color="red"
          title={`${p.title.rendered} Success Stories`}
        >
          <div className="col-md-12">
            {p.stickers.length && <Stickers items={p.stickers} />}
          </div>
        </SectionFull>
      ) : null}
    </Fragment>,
    <Stories stories={p.stories} />,
    <People
      people={p.people}
      title={
        p.sectionPeople ? p.sectionPeople : "Meet the Mayor & Mayoral Commitee"
      }
      highlight={p.highlight}
    />,
    <Videos videos={p.videos} title={"Latest Videos"} />
  ];
};

export default BaseGovernment;
