import React, { Fragment } from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import {
  variables,
  Copy,
  NavItem,
  Hero,
  Loading,
  SectionFull
} from "@website2018/da-dobsonville";
import Sticky from "react-stickynode";
import { Link } from "react-router-dom";

import Asides from '../Asides/Asides';
import Videos from '../Videos/Videos';
import NoSSR from '../Nav/NoSSR';
import SubMenu from '../Nav/SubMenu';
import MetaHeader from '../Meta/MetaHeader';

import { getTaxLink, noComma, sanitarize } from "../../common/utils";
import MunicipalitySearch from "../../containers/Search/MunicipalitySearchContainer";

import qs from "qs";
import * as R from "ramda";

class MunicipalityNewsTemplate extends React.Component {
  render() {
    const { error, loading, page: p, videos, taxonomy } = this.props;
    const { fontWeight, colors } = variables;

    if (loading) return <Loading />;
    if (error) return <h1>ERROR</h1>;

    const getTaxonomy = R.path(["refinementList", "taxonomies", "category", 0]);
    const taxonomyHeadline = getTaxonomy(qs.parse(this.props.location.search));

    return [
      <MetaHeader p={p} />,
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu>
          <NavItem
            fontWeight={fontWeight.medium}
            color={colors.textBase}
            fs={"13px"}
            lh={"16px"}
          >
            <Link to={`/newsroom`}>Newsroom</Link>
          </NavItem>
          {taxonomy
            .filter(c => c.name !== "News")
            .map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={getTaxLink(c.taxonomy, c.name, "newsroom")}>
                  {c.name}
                </Link>
              </NavItem>
            ))}
        </SubMenu>
      </Sticky>,
      <Hero
        imageUrl={
          p.hero && p.hero.background_image ? p.hero.background_image.url : null
        }
        title={
          taxonomyHeadline
            ? sanitarize(taxonomyHeadline)
            : p.hero && p.hero.headline
            ? sanitarize(p.hero.headline)
            : p.title.rendered
        }
        bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      />,
      <Fragment>
        {p.content.rendered && (
          <SectionFull>
            <div className={"col-lg-7"}>
              <Copy intro html={p.content.rendered} />
            </div>
          </SectionFull>
        )}
      </Fragment>,
      <MunicipalitySearch
        showTitle={false}
        type="posts"
        refinements={[
          {
            title: "Municipality",
            attribute: "taxonomies.municipality"
          }
        ]}
        renderWidgets={() => (
          <NoSSR>{p.asides && <Asides asides={p.asides} />}</NoSSR>
        )}
        {...this.props}
      />,

      <Videos videos={videos} title={"Latest Videos"} />
    ];
  }
}

const TEMPLATE_QUERY = gql`
  query getTemplate($taxInput: TaxInputQuery) {
    taxonomy(input: $taxInput) {
      id
      name
      slug
      taxonomy
    }
    videos {
      id
      videoId
      title {
        rendered
      }
      excerpt {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
    page(slug: "newsroom") {
      id
      slug
      seo
      template
      acf
      title {
        rendered
      }
      content {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      hero {
        headline
        description
        cta_type
        cta_group {
          title
          link_label
          link_url
        }
        background_image {
          url
        }
        focus_point
        countdown {
          headline
          date
        }
      }
      testimonials {
        section_heading
        testimonial {
          headline
          attribution
          photo {
            url
          }
          text
        }
      }
      stickers {
        title
        icon
        background {
          url
        }
        color
        text
      }
      list {
        hasList
        section_heading
        collapsible
        list_items {
          title
          text
          image {
            url
          }
          icon
        }
      }
      parent {
        slug
        title {
          rendered
        }
        children {
          slug
          title {
            rendered
          }
        }
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      asides_grid {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      children {
        id
        slug
        title {
          rendered
        }
        content {
          rendered
        }
      }
      timeline {
        date
        headline
        content
        image {
          url
        }
      }
    }
  }
`;

const withTemplate = graphql(TEMPLATE_QUERY, {
  options: props => ({
    variables: {
      slug: "newsroom",
      taxInput: { taxonomy: "categories" }
    }
  }),
  props: ({ data, post }) => ({ ...data })
});

export default withTemplate(MunicipalityNewsTemplate);
