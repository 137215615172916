import React, { Fragment } from "react";
import {
  Copy,
  Hero,
  SectionFull,
  Sidebar,
  Heading,
  MoreLink,
  CardCampaign,
  CardHighlight,
  CardPerson,
  Image,
  variables
} from "@website2018/da-dobsonville";
import YouTubeVideo from "../Videos/YouTubeVideo";
import { noComma } from "../../common/utils";
import { Link } from "react-router-dom";
import { Asides, MetaHeader, Layout, SliderWrapper, Spacing } from "..";

const CampaignOneTemplate = props => {
  const { campaign: p } = props;
  const { spacing } = variables;

  return [
    <MetaHeader p={p} />,

    <Hero
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
    />,
    <Fragment>
      {p.content.rendered && (
        <SectionFull mt={"none"}>
          <div className={"col-lg-7"}>
            <article className="entry">
              <Copy intro html={p.content.rendered} />
              {p.campaign_gallery && (
                <Fragment>
                  <Spacing top size={spacing.space4}>
                    <Heading mb="medium" color="blue">
                      {p.campaign_gallery.headline}{" "}
                    </Heading>
                    <SliderWrapper col="col-md-12">
                      {p.campaign_gallery.images &&
                        p.campaign_gallery.images.map(t => (
                          <Spacing bottom size={spacing.space2}>
                            <Image imageUrl={t.url} />
                          </Spacing>
                        ))}
                    </SliderWrapper>
                  </Spacing>
                </Fragment>
              )}
              {p.acf.layout_content && p.acf.layout_content.length ? (
                <Layout layout={p.acf.layout_content} />
              ) : null}
            </article>
          </div>
          <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
        </SectionFull>
      )}
    </Fragment>,

    <Fragment>
      {p.acf.layout && p.acf.layout.length ? (
        <Layout layout={p.acf.layout} />
      ) : null}
    </Fragment>,

    <Fragment>
      <SectionFull
        color="red"
        title={p.acf.onesouthafricaforall.plan.headline}
        rowClass=""
        mt="large"
      >
        <div className="row">
          <div className="col-12">
            <Copy intro html={p.acf.onesouthafricaforall.plan.content} />
          </div>
        </div>
        <div className="row">
          {p.acf.onesouthafricaforall.plan.items.map(i => {
            const inner = (
              <CardCampaign
                full
                key={i.id}
                title={i.title}
                imageUrl={i.image}
                renderCta={hover => (
                  <MoreLink hover={hover} color={"red"}>
                    View More
                  </MoreLink>
                )}
              />
            );

            return (
              <div className={"col-md-4"}>
                <Link to={`/campaigns/${i.slug}`} key={i.id}>
                  {inner}
                </Link>
              </div>
            );
          })}
        </div>
      </SectionFull>
    </Fragment>,

    <Fragment>
      <SectionFull
        pt={"large"}
        color={"blue"}
        bgColor={"light"}
        mb={"none"}
        title={p.acf.onesouthafricaforall.musi_team_section.headline}
      >
        <div className={"col-md-12"}>
          <CardHighlight
            imageUrl={p.acf.onesouthafricaforall.musi_team_section.intro.image}
            title={p.acf.onesouthafricaforall.musi_team_section.intro.title}
            paragraph={
              p.acf.onesouthafricaforall.musi_team_section.intro.content
            }
          />
        </div>
      </SectionFull>
      <SectionFull
        bgColor={"light"}
        mb={"none"}
        pt={"none"}
        pb={"large"}
        container={"container"}
      >
        <div className={"col-md-12"}>
          <div className={"row"}>
            {p.acf.onesouthafricaforall.musi_team_section.team_members
              .length ? (
              <div className={"col-md-12"}>
                <div className="row">
                  {p.acf.onesouthafricaforall.musi_team_section.team_members.map(
                    t => (
                      <div className="col-md-6">
                        <CardPerson
                          imageUrl={t.image}
                          title={t.name}
                          meta={t.role}
                          border={false}
                        >
                          <MoreLink>Read More</MoreLink>
                        </CardPerson>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </SectionFull>
    </Fragment>,

    <SectionFull mb="none" pt="large" pb="large" bgColor="blueLight">
      <div className="col-md-12">
        <CardHighlight
          mb="none"
          title={p.acf.onesouthafricaforall.cta.headline}
          paragraph={p.acf.onesouthafricaforall.cta.content}
          imageUrl={p.acf.onesouthafricaforall.cta.image}
          videoId={p.acf.onesouthafricaforall.cta.video_id}
          bgColor="blue"
          renderVideo={() => (
            <YouTubeVideo videoId={p.acf.onesouthafricaforall.cta.video_id} />
          )}
        />
      </div>
    </SectionFull>,

    <Fragment>
      <SectionFull
        pt={"large"}
        color={"blue"}
        bgColor={"light"}
        mb={"none"}
        title={p.acf.onesouthafricaforall.premier_canidates.headline}
      >
        <div className={"col-md-12"}>
          <CardHighlight
            imageUrl={p.acf.onesouthafricaforall.premier_canidates.intro.image}
            title={p.acf.onesouthafricaforall.premier_canidates.intro.title}
            paragraph={
              p.acf.onesouthafricaforall.premier_canidates.intro.content
            }
          />
        </div>
      </SectionFull>
      <SectionFull
        bgColor={"light"}
        mb={"none"}
        pt={"none"}
        pb={"large"}
        container={"container"}
      >
        <div className={"col-md-12"}>
          <div className={"row"}>
            {p.acf.onesouthafricaforall.premier_canidates.candidate_options
              .length ? (
              <div className={"col-md-12"}>
                <div className="row">
                  {p.acf.onesouthafricaforall.premier_canidates.candidate_options.map(
                    p => (
                      <div className="col-md-6">
                        <CardPerson
                          imageUrl={p.image}
                          title={p.name}
                          meta={p.role}
                          border={false}
                        >
                          <MoreLink>Read More</MoreLink>
                        </CardPerson>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </SectionFull>
    </Fragment>
  ];
};

export default CampaignOneTemplate;
