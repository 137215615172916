import React, { Fragment } from "react";
import {
  SectionFull,
  Copy,
  Heading,
  CardCampaign,
  MoreLink,
} from "@website2018/da-dobsonville";
import { AppLink } from "../Nav/AppLink";

export const ItemsComponent = ({ headline, content, items }) => {
  return (
    <SectionFull color="red" title={headline}>
      {content ? (
        <div className="row">
          <div className="col-12">
            <Copy intro html={content} />
          </div>
        </div>
      ) : null}

      {items.map((i) => {
        const inner = (
          <CardCampaign
            full
            mb="small"
            key={i.id}
            title={null}
            imageUrl={i.image}
            renderCta={(hover) => (
              <Fragment>
                <Heading color="blue">{i.title}</Heading>
                <AppLink
                  link={i.link}
                  renderInner={() => (
                    <MoreLink hover={hover} color={"red"}>
                      {i.link_label ? i.link_label : "View More"}
                    </MoreLink>
                  )}
                />
              </Fragment>
            )}
          />
        );

        return <div className={"col-md-4"}>{inner}</div>;
      })}
    </SectionFull>
  );
};
