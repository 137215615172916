import { Component } from 'react';

class HasMounted extends Component {
    constructor(props) {
        super(props);
        this.state = { hasMounted: false };
    }

    componentDidMount() {
        this.setState({ hasMounted: true });
    }

    render() {
        if (this.state.hasMounted) return this.props.children;

        return null;
    }
}

export default HasMounted;