import React from "react";
import { Copy } from '@website2018/da-dobsonville';
import ListWrapper from "../Nav/List";
import ListItem from "../Nav/ListItem";
import Spacing from "../Spacing/Spacing";

const FAQComponent = ({faqs}) => {
  return (
    <ListWrapper title={""}>
      {faqs.map(item => (
        <ListItem title={item.title} collapsed>
          <Spacing top bottom size="space2">
            <Copy html={item.content} />
          </Spacing>
        </ListItem>
      ))}
    </ListWrapper>
  );
};

export default FAQComponent;