import React, { Fragment } from "react";
import {
  Copy,
  Hero,
  SectionFull,
  Sidebar,
  Heading,
  Image,
  variables
} from "@website2018/da-dobsonville";
import { noComma } from "../../common/utils";

import {
  Asides,
  Champ,
  MetaHeader,
  Layout,
  Posts,
  SliderWrapper,
  Spacing
} from "..";

const CampaignTeamTemplate = props => {
  const { campaign: p } = props;
  const { spacing } = variables;

  return [
    <MetaHeader p={p} />,

    <Hero
      center
      small={false}
      mb="none"
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      description={p.hero && p.hero.description ? p.hero.description : null}
      bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
    />,

    <Champ
      title={"Together , let’s build One South Africa for All!"}
      formId={"campaign"}
    />,

    <Fragment>
      {p.content.rendered && (
        <SectionFull mt={"none"}>
          <div className={"col-lg-7"}>
            <article className="entry">
              <Copy intro html={p.content.rendered} />
              {p.campaign_gallery && (
                <Fragment>
                  <Spacing top size={spacing.space4}>
                    <Heading mb="medium" color="blue">
                      {p.campaign_gallery.headline}{" "}
                    </Heading>
                    <SliderWrapper col="col-md-12">
                      {p.campaign_gallery.images &&
                        p.campaign_gallery.images.map(t => (
                          <Spacing bottom size={spacing.space2}>
                            <Image imageUrl={t.url} />
                          </Spacing>
                        ))}
                    </SliderWrapper>
                  </Spacing>
                </Fragment>
              )}
              {p.acf.layout_content && p.acf.layout_content.length ? (
                <Layout layout={p.acf.layout_content} />
              ) : null}
            </article>
          </div>
          <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
        </SectionFull>
      )}
    </Fragment>,
    <Fragment>
      {p.acf.layout && p.acf.layout.length ? (
        <Layout layout={p.acf.layout} />
      ) : null}
    </Fragment>,

    p.posts && <Posts posts={p.posts} title={"Related News"} />
  ];
};

export default CampaignTeamTemplate;
