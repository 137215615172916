import React, { Fragment } from "react";
import {
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  Configure
} from "react-instantsearch/dom";
import {
  SectionFull,
  Heading,
  Hero,
  Sidebar,
  variables
} from "@website2018/da-dobsonville";
import "react-instantsearch-theme-algolia/style.css";
import { connectMenu } from "react-instantsearch/connectors";
import qs from "qs";

import SearchResults from "../../components/Search/SearchResults";
import NoSSR from '../../components/Nav/NoSSR';
import Spacing from '../../components/Spacing/Spacing';
import { sanitarize } from "../../common/utils";

const updateAfter = 700;
const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : "";

class MunicipalitySearchContainer extends React.Component {
  constructor(props) {
    super(props);

    const search = qs.parse(props.location.search.slice(1));

    this.state = {
      searchState: {
        ...search,
        refinementList: {
          taxonomies: {
            category: ["Where We Govern"]
          }
        }
      },
      refinement: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const search = qs.parse(this.props.location.search.slice(1));

      const searchParams = search;

      const refinements = {
        post_type_label: "Posts",
        refinement: true,
        ...(searchParams.refinementList || {})
      };

      this.setState(state => ({
        searchState: {
          ...searchParams,
          refinementList: {
            taxonomies: {
              category: ["Where We Govern"]
            },
            ...refinements
          }
        },
        refinement:
          this.props.location.search &&
          this.props.location.search.page !== prevProps.location.search.page
            ? false
            : true
      }));
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState);
    this.debouncedSetState = setTimeout(() => {
      this.props.history.push(
        searchStateToUrl(this.props, searchState),
        searchState
      );
    }, updateAfter);
    this.setState({ searchState, refinement: false });
  };

  clearSearch = () => {
    this.setState({
      searchState: {
        refinementList: {
          post_type_label: "Posts",
          refinement: true
        }
      }
    });
  };

  render() {
    const { spacing } = variables;

    return (
      <InstantSearch
        appId="6J2DNE3OT9"
        apiKey="252c455b15cdf890907eeb941b4c8c37"
        indexName="wp_posts_municipality_news"
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange.bind(this)}
        createURL={createURL}
      >
        <Configure hitsPerPage={10} />
        {this.props.showTitle ? (
          <Hero
            title={
              this.state.searchState.query
                ? sanitarize(`Search for “${this.state.searchState.query}”`)
                : "Search"
            }
            small
          />
        ) : null}
        <SectionFull>
          <div className="col-lg-7 results__wrapper">
            <NoSSR>
              <SearchResults type={this.props.type || null} />
            </NoSSR>
            <Pagination showLast={true} />
          </div>
          <Sidebar>
            <Spacing bottom size={spacing.space2}>
              <SearchBox />
            </Spacing>
            {this.state.refinement && this.props.defaultRefinement ? (
              <VirtualMenu
                attributeName={this.props.defaultRefinement.attribute}
                defaultRefinement={this.props.defaultRefinement.value}
              />
            ) : null}
            {this.props.refinements ? (
              <Fragment>
                {this.props.refinements.map(r => (
                  <Spacing bottom size={spacing.space2}>
                    <Heading
                      size={"tiny"}
                      weight={"bold"}
                      letterSpacing={"small"}
                      mb={"tiny"}
                      uppercase
                    >
                      {r.title}
                    </Heading>
                    <RefinementList
                      key={r.attribute}
                      attributeName={r.attribute}
                      {...r}
                    />
                  </Spacing>
                ))}
              </Fragment>
            ) : null}
            {this.props.renderWidgets ? this.props.renderWidgets() : null}
          </Sidebar>
        </SectionFull>
      </InstantSearch>
    );
  }
}

const VirtualMenu = connectMenu(() => null);

export const dateRegex = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])T([0-2][0-9]):([0-5][0-9]):([0-5][0-9])/;

export default MunicipalitySearchContainer;
