import React, { Fragment } from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { noComma } from "../../common/utils";
import {
  Copy,
  Hero,
  Loading,
  NavItem,
  SectionFull,
  Sidebar,
  Testimonial,
  variables,
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import Asides from '../Asides/Asides'
import SubMenu from '../Nav/SubMenu'
import SliderWrapper from '../Slider/Slider'
import Videos from '../Videos/Videos'

class WTDTemplate extends React.Component {
  render() {
    const { fontWeight, colors } = variables;
    const { loading, videos, policies, error, page: p } = this.props;

    if (loading) return <Loading />;
    if (error) return <h1>ERROR</h1>;

    return [
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu items={p.children}>
          {p.children.map((c) => (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.linkBlue}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.slug}/${c.slug}`}>{c.title.rendered}</Link>
            </NavItem>
          ))}
        </SubMenu>
      </Sticky>,
      <Hero
        small
        imageUrl={
          p.hero && p.hero.background_image ? p.hero.background_image.url : null
        }
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      />,
      <Fragment>
        {p.content.rendered && (
          <SectionFull mt="none">
            <div className={"col-lg-7"}>
              <Copy intro html={p.content.rendered} />
            </div>
            <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
          </SectionFull>
        )}
      </Fragment>,
      <Videos videos={videos} title={"Why the DA?"} />,
      <Fragment>
        {p.testimonials.testimonial && p.testimonials.testimonial.length ? (
          <SectionFull
            bgColor={"light"}
            pt={"large"}
            pb={"large"}
            mb={"none"}
            container={"container"}
          >
            <SliderWrapper>
              {p.testimonials.testimonial.map((t) => (
                <div>
                  <Testimonial
                    headline={t.headline}
                    text={t.text}
                    imageUrl={(t.photo.sizes && t.photo.sizes.medium) || t.photo.url}
                    meta={t.attribution}
                  />
                </div>
              ))}
            </SliderWrapper>
          </SectionFull>
        ) : null}
      </Fragment>,
    ];
  }
}

const TEMPLATE_QUERY = gql`
  query getTemplate($slug: String!) {
    videos(slug: $slug) {
      id
      videoId
      title {
        rendered
      }
      excerpt {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
    policies(slug: $slug) {
      id
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
  }
`;

const withTemplate = graphql(TEMPLATE_QUERY, {
  options: (props) => ({
    variables: { slug: props.match.params.slug },
  }),
  props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(WTDTemplate);
