import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading, SectionFull } from "@website2018/da-dobsonville";
import { path } from "ramda";
import { Redirect } from "react-router-dom";

import FormContainer from '../Data/FormContainer';
import FormBuilderContainer from '../Data/FormBuilderContainer';
import Job from '../../components/Job/Base';
import MetaHeader from '../../components/Meta/MetaHeader';

class JobContainer extends React.Component {
  state = {
    application: {
      uploads: {}
    },
    errors: [],
    sent: false,
    loading: true
  };

  updateApplicationForm = e => {
    this.setState({
      application: {
        ...this.state.application,
        [e.target.name]: e.target.value
      }
    });
  };

  updateApplicationFiles = ([file], field) => {
    const { FileUpload } = this.props;

    if (!!file) {
      FileUpload({
        variables: { file }
      })
        .then(({ data: { singleUpload } }) => {
          this.setState({
            errors: {
              [field]: null
            },
            application: {
              ...this.state.application,
              uploads: {
                ...this.state.application.uploads,
                [field]: {
                  ...singleUpload
                }
              }
            }
          });
        })
        .catch(err => {
          let errors = err.graphQLErrors
            ? path(["graphQLErrors", 0, "message"])(err)
            : ["Unexpected error."];

          this.setState(state => ({
            errors: {
              ...state.errors,
              [field]: errors
            }
          }));
        });
    } else {
      this.setState({
        application: {
          ...this.state.application,
          uploads: {
            ...this.state.application.uploads,
            [field]: null
          }
        }
      });
    }
  };

  onSubmit = async (values, errors) => {
    const {
      job: {
        meta: {
          job: { adminEmail = "applications@da.org.za" }
        }
      }
    } = this.props;
    this.props
      .submitForm({
        variables: {
          input: {
            form: "apply",
            fields: {
              ...values,
              adminEmail,
              uploads: this.state.application.uploads
            }
          }
        }
      })
      .then(data => {
        this.props.history.push("/thank-you-for-applying");
      });
  };

  render() {
    const { loading, job, error } = this.props;
    if (loading) return <Loading />;
    if (error || !job) return <Redirect to={"/not-found"} />;

    return [
      <MetaHeader p={job} />,
      <Job job={job} />,
      <SectionFull
        title="Application Form"
        pt={"none"}
        pb={"large"}
        mb={"none"}
      >
        <div className="col-md-12">
          <FormContainer
            formId={"apply"}
            render={form => (
              <FormBuilderContainer
                form={form}
                handleSubmit={this.onSubmit}
                inputOptions={{
                  onChange: e => this.updateApplicationForm(e)
                }}
                fileOptions={{
                  onChange: this.updateApplicationFiles,
                  uploads: this.state.application.uploads,
                  errors: this.state.errors
                }}
                values={{
                  ...this.state.application,
                  jobTitle: job.title.rendered,
                  jobReference: job.meta.job.reference
                }}
                readOnly={{
                  jobTitle: job.title.rendered,
                  jobReference: job.meta.job.reference
                }}
              />
            )}
          />
        </div>
      </SectionFull>
    ];
  }
}

const UPLOAD_FILE = gql`
  mutation($file: Upload!) {
    singleUpload(file: $file) {
      _id
      filename
    }
  }
`;

const JOB_QUERY = gql`
  query getJob($slug: String!) {
    job(slug: $slug) {
      id
      seo
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      content {
        rendered
      }
      meta {
        job {
          reference
          date
          adminEmail
          province {
            name
          }
        }
      }
    }
  }
`;

const withUpload = graphql(UPLOAD_FILE, { name: "FileUpload" });

const withJob = graphql(JOB_QUERY, {
  options: props => ({
    variables: { slug: props.match.params.slug }
  }),
  props: ({ data, post }) => ({ ...data })
});

const ADD_SUBMISSION_MUTATION = gql`
  mutation submitForm($input: SubmissionInput!) {
    submitForm(input: $input)
  }
`;

const withSubmission = graphql(ADD_SUBMISSION_MUTATION, { name: "submitForm" });

export default withSubmission(withUpload(withJob(JobContainer)));
