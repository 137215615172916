import React, { Fragment } from 'react'
import { graphql } from '@apollo/client/react/hoc';
import Media from "react-media";
import { gql } from "@apollo/client";
import { noComma } from '../../common/utils'
import { Aside, Copy, Heading, Hero, Loading, SectionFull, variables } from '@website2018/da-dobsonville';
import { StyleSheet, css } from "aphrodite";

class HistoryTemplate extends React.Component {

  state = {
    selected: null
  }

  render() {
    const { colors, spacing } = variables;
    const { error, loading, page:p } = this.props;    

    if (loading) return <Loading/>;
    if (error) return <h1>ERROR</h1>;

    const styles = {
      timeline:{
        listStyle: 'none',
        position: 'relative',
        padding: '0',
        margin: '0',
        paddingTop: spacing.space15,
        paddingBottom: spacing.space10,
      },
      item:{
        marginBottom: spacing.space5,        
        position: 'relative',
        width: '50%',
        float: 'left',
        clear: 'left',
        ':before':{
          content:" ",
          display: 'table',
        },
        ':after':{
          content:" ",
          display: 'table',
          clear: 'both',
        },
        '@media (max-width: 767px)': {
          marginBottom: spacing.space2,    
          width:'100%',
        },
      },
      wrapper:{
        paddingRight: spacing.space6,        
        '@media (max-width: 767px)': {
          paddingLeft: spacing.space8,
          paddingRight: spacing.space4,
        },
      },
      wrapperInverted:{
        paddingLeft: spacing.space6,
        paddingRight: "0px",
      },
      inverted:{
        float: 'right',
        clear: 'right',
        ":nth-child(2)":{
          marginTop: `calc(${spacing.space10} * 2)`,
        },
        '@media (max-width: 767px)': {
          float: 'left',
          clear: 'left',
          marginTop: spacing.space3,
        }
      },
      dot:{
        border: '2px solid',
        borderColor: colors.brandBlue,
        backgroundColor: colors.baseWhite,
        borderRadius: '50%',
        width: spacing.space3,
        height: spacing.space3,
        lineHeight: '50px',
        textAlign: 'center',
        position: 'absolute',
        top: '18px',
        right: '-12px',
        zIndex: '100',
        '@media (max-width: 767px)': {
          left: '20px',
          marginLeft: 0,
        },
        '@media (max-width: 575px)': {
          top: '7px',
          left: '24px',
          width: spacing.space2,
          height: spacing.space2,
        },
      },
      dotInverted:{
        left: '-12px',
        right: 'inherit',
      }
    };

    const baseStyles = StyleSheet.create({
      timeline: {
        ...styles.timeline,
      },
      wrapper: {
        ...styles.wrapper,
      },
      wrapperInverted: {
        ...styles.wrapperInverted,
      },
      item: {
        ...styles.item,
      },
      inverted: {
        ...styles.inverted,
      },
      dot: {
        ...styles.dot,
      },
      dotInverted: {
        ...styles.dotInverted,
      }
    });

    return ([
      <Hero
        center
        imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
      />,

      <Fragment>
        { p.content.rendered &&
          <SectionFull>
            <div className={"col-lg-8 ml-auto mr-auto"}>
              <Copy intro html={p.content.rendered} />
            </div>
          </SectionFull>
        }
      </Fragment>,

      <Fragment>
        { p.timeline &&          
          <SectionFull
            pb="none"
            mb="none"
            bgColor="light">
            <ul className={`timeline ${css(baseStyles.timeline)}`}>
              { p.timeline && p.timeline.map((t,index) =>
                <li key={index} className={css(baseStyles.item, index % 2 ? baseStyles.inverted:'')}>
                  <div className={css(baseStyles.wrapper, index % 2 ? baseStyles.wrapperInverted:'')}>
                    <span className={css(baseStyles.dot, index % 2 ? baseStyles.dotInverted:'')}></span>

                    <Media query={{ maxWidth: 767 }}>
                      {matches =>
                        matches ? (
                          <Heading size="huge" color="blue" align="left" mb="medium">{t.date}</Heading>
                        ) : (
                          <Heading size="huge" color="blue" align={index % 2 ? 'left' : 'right'} mb="medium">{t.date}</Heading>
                        )
                      }
                    </Media>

                    <Aside
                      bgColor="white"
                      vertical
                      imageUrl={t.image.url}
                      title={t.headline}
                    >
                      <Copy html={t.content}/>
                    </Aside>
                  </div>
                </li>
              )}
            </ul>
          </SectionFull>
        }
      </Fragment>
    ]);
  }
}

const TEMPLATE_QUERY = gql`
  query getTemplate {
    hostory: page(slug: "history") {
      id
    }
  }
`;

const withTemplate = graphql(TEMPLATE_QUERY, {
  props: ({ data, post }) => ({ ...data }),
});

export default withTemplate(HistoryTemplate);
