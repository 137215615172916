import React from 'react'
import { ArticleHeader, ButtonTest } from '@website2018/da-dobsonville';
import Modal from 'react-modal';
import PersonFooter from './PersonFooter'

export const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    zIndex            : 1060,
    backgroundColor   : 'rgba(0, 0, 0, 0.6)'
  },
  content : {
    position                   : 'absolute',
    top                        : '0',
    left                       : '0',
    right                      : '0',
    bottom                     : '0',
    border                     : '0',
    background                 : 'rgba(255, 255, 255, 1)',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '8rem 0 6rem'
  }
};

class PersonModal extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

  }

  componentDidUpdate(prevProps) {

  }

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
      >
        <div className="container">
        { this.props.p ? <PersonInfo p={this.props.p} /> : null }
        <ButtonTest onClick={this.props.closeModal} outline>Close</ButtonTest>
        </div>
      </Modal>

    );
  }
}

const PersonInfo = ({ p }) => {
  return (
    <div>
      <ArticleHeader
        subHeading={p.province || null }
        title={p.title || null }
        meta={p.role}
      />
			<PersonFooter p={p}/>
    </div>
  )
}

export default PersonModal;
