import React, { Fragment } from "react";
import { graphql } from '@apollo/client/react/hoc';
import { Loading } from "@website2018/da-dobsonville";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import {
  CardVideo,
  MoreLink,
  SectionFull,
  ViewAll
} from "@website2018/da-dobsonville";
import Media from "react-media";
import YouTubeVideo from '../Videos/YouTubeVideo'

const Videos = props => {
  if (!props.videos_category) {
    return null;
  }

  const one = props.videos[0] || null;
  const two = props.videos[1] || null;
  const three = props.videos[2] || null;
  const four = props.videos[3] || null;
  const five = props.videos[4] || null;

  return (
    <SectionFull
      bgColor="blueLight"
      color="white"
      title={props.videos_heading}
      mb="none"
      pb="large"
      pt="large"
    >
      <Media query="(min-width: 992px)">
        {matches =>
          matches ? (
            <Fragment>
              <div className="row">
                <div className="col-md-7">
                  <YouTubeVideo
                    key={one.videoId}
                    videoId={one.videoId}
                    title={one.title.rendered}
                    render={() => (
                      <CardVideo
                        videoId={one.videoId}
                        featured
                        imageUrl={null}
                        title={one.title.rendered}
                        paragraph={one.excerpt.rendered}
                        cta="Play Video"
                        color="white"
                      />
                    )}
                  />
                </div>
                <div className="col-md-5">
                  <div className="row">
                    {two && (
                      <div className="col-md-6">
                        <YouTubeVideo
                          key={two.videoId}
                          videoId={two.videoId}
                          title={two.title.rendered}
                          render={() => (
                            <CardVideo
                              imageUrl={null}
                              title={two.title.rendered}
                              color="white"
                            />
                          )}
                        />
                      </div>
                    )}
                    {three && (
                      <div className="col-md-6">
                        <YouTubeVideo
                          key={three.videoId}
                          videoId={three.videoId}
                          title={three.title.rendered}
                          render={() => (
                            <CardVideo
                              imageUrl={null}
                              title={three.title.rendered}
                              color="white"
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    {four && (
                      <div className="col-md-6">
                        <YouTubeVideo
                          key={four.videoId}
                          videoId={four.videoId}
                          title={four.title.rendered}
                          render={() => (
                            <CardVideo
                              imageUrl={null}
                              title={four.title.rendered}
                              color="white"
                            />
                          )}
                        />
                      </div>
                    )}
                    {five && (
                      <div className="col-md-6">
                        <YouTubeVideo
                          key={five.videoId}
                          videoId={five.videoId}
                          title={five.title.rendered}
                          render={() => (
                            <CardVideo
                              imageUrl={null}
                              title={five.title.rendered}
                              color="white"
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="col-12" key={"mobile-video"}>
              <YouTubeVideo
                key={`mobile-${one.videoId}`}
                videoId={one.videoId}
                title={one.title.rendered}
                render={() => (
                  <CardVideo
                    videoId={one.videoId}
                    featured
                    imageUrl={null}
                    title={one.title.rendered}
                    paragraph={one.excerpt.rendered}
                    cta="Play Video"
                    color="white"
                  />
                )}
              />
            </div>
          )
        }
      </Media>

      <div className="col-md-12">
        <a href={props.view_all_link} target="_blank" rel="noopener noreferrer">
          <ViewAll
            borderColor="rgba(255,255,255,0.15)"
            buttonColor="white"
            href={props.view_all_link}
          >
            View all Videos
          </ViewAll>
        </a>
      </div>
    </SectionFull>
  );
};

class VideosComponent extends React.Component {
  render() {
    const { error, loading, videos } = this.props;

    if (loading) return <Loading />;
    if (error || !videos) return <p>{JSON.stringify(error)}</p>;
    return <Videos {...this.props} />;
  }
}

const VIDEO_QUERY = gql`
  query getVideos($categoryId: Int) {
    videos(categoryId: $categoryId) {
      id
      videoId
      title {
        rendered
      }
      excerpt {
        rendered
      }
      category {
        id
        name
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
  }
`;

const withPage = graphql(VIDEO_QUERY, {
  options: props => {
    return {
      variables: {
        categoryId: Number(props.videos_category)
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default (VideosComponent = withPage(VideosComponent));
