import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from "@website2018/da-dobsonville";
import CampaignsTemplate from '../../components/Campaigns/Base';
import { Redirect } from "react-router-dom";

class CampaignsContainer extends React.Component {
  render() {
    const { error, loading, page } = this.props;

    if (loading) return <Loading />;
    if (error || !page) return <Redirect to={"/not-found"} />;

    return <CampaignsTemplate {...this.props} />;
  }
}

const CAMPAIGNS_QUERY = gql`
  query getCampaigns {
    page(slug: "campaigns") {
      id
      slug
      seo
      excerpt {
        rendered
      }
      title {
        rendered
      }
      content {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
    }
    latest: campaigns(
      meta_key: "campaign_status"
      meta_value: "current"
      taxonomy: "province"
      term: "national"
    ) {
      id
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
        media_details {
          sizes {
            medium_large { source_url }
          }
        }
      }
      external {
        status
        link
      }
    }
    archive: campaigns(
      meta_key: "campaign_status"
      meta_value: "past"
      taxonomy: "province"
      term: "national",
      first: 100
    ) {
      id
      seo
      slug
      title {
        rendered
      }
      external {
        status
        link
      }
    }
  }
`;

const withCampaigns = graphql(CAMPAIGNS_QUERY, {
  props: ({ data }) => ({ ...data })
});

export default withCampaigns(CampaignsContainer);
