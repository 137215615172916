import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { variables, Border, ButtonTest, Widget, Tag, NavIcon  } from '@website2018/da-dobsonville';
import { Link } from 'react-router-dom';
import Media from "react-media";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import { getTaxLink } from '../../common/utils';

const ArticleFooter = ({ p }) => {
  const styles = StyleSheet.create({
    articleFooter:{},
    articleDetails:{},
    articleShare:{}
  })
  const { colors } = variables;

  let url = window ? window.location.href : null;

  return (
    <footer>
      <section>
        {
          p.formattedDate &&
          <Widget
            colTitle={"col-md-3"}
            colSection={"col-md-9"}
            widgetTitle={"Publication Date"}>
              <Tag>{p.formattedDate}</Tag>
          </Widget>
        }
        {
          p.meta && p.meta.person && p.meta.person.hasMember &&
          <Widget
            colTitle={"col-md-3"}
            colSection={"col-md-9"}
            widgetTitle={"Author"}>
            <Tag>{p.meta.person.member}</Tag>
          </Widget>
        }
        {
          p.category && p.category.length && <Widget
            colTitle={"col-md-3"}
            colSection={"col-md-9"}
            widgetTitle={"Category"}>
            {
              p.category.map(c => <Link to={getTaxLink(c.taxonomy,c.name)}><Tag hover key={c.id}>{c.name}</Tag></Link> )
            }
          </Widget>
        }
        {
          p.tag && p.tag.length && <Widget
            colTitle={"col-md-3"}
            colSection={"col-md-9"}
            widgetTitle={"Tags"}>
            {
              p.tag.map(t => <Link to={getTaxLink(t.taxonomy,t.name)}><Tag hover key={t.id}>{t.name}</Tag></Link> )
            }
          </Widget>
        }
      </section>

      <section className={css(styles.articleShare)}>
        <Media query="(min-width: 576px)">
          { matches => matches ? (
            <Border
              mt={"large"}
              mb={"large"}
            />
          ) : (
            <Border
              mt={"small"}
              mb={"medium"}
            />
        )}
      </Media>
        <Widget
          widgetTitle={"Direct Link"}
          widgetTitleColor={"blue"}
          colTitle={"col-md-12"}
          colSection={"col-md-12"}>
          <div className="form-group">
            <div className="input-group">
              <input type="text" className="form-control" id="link" placeholder={url} readonly></input>
              <CopyToClipboard text={url}>
                <div className="input-group-append">
                  <ButtonTest customStyles={{borderRadius: '0 30px 30px 0 !important'}}>Copy</ButtonTest>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </Widget>

        <Media
          query="(min-width: 576px)"
          render={() =>
            <Border
              mt={"small"}
              mb={"none"}
              color={'whiteO'}
            />
          }
        />

        <Widget
          widgetTitle={"Share"}
          widgetTitleColor={"blue"}
          colTitle={"col-md-12"}
          colSection={"col-md-12"}>
          <div className="row">
            <div className="col-md-6">
              <FacebookShareButton url={url} ><NavIcon iconType="bootstrap" iconName="facebook" iconColor={colors.socialFacebook}>{"Share on Facebook"}</NavIcon></FacebookShareButton>
              <WhatsappShareButton title={p.title.rendered} url={url} ><NavIcon iconType="bootstrap" iconName="whatsapp" iconColor={colors.socialWhatsApp}>{"Share with WhatsApp"}</NavIcon></WhatsappShareButton>
              <TwitterShareButton url={url}><NavIcon iconType="bootstrap" iconName="twitter-x" iconColor={colors.socialTwitter}>{"Share on X (Twitter)"}</NavIcon></TwitterShareButton>
            </div>

            <div className="col-md-6">
              <EmailShareButton subject={p.title.rendered} body={url}><NavIcon iconType="bootstrap" iconName="envelope-fill" iconColor={colors.socialEmail}>{"Send by Email"}</NavIcon></EmailShareButton>
              <NavIcon onPress={() => window.print()} iconType="bootstrap" iconName="printer-fill" iconColor={colors.brandBlue}>{"Print"}</NavIcon>
            </div>
          </div>
        </Widget>
      </section>
      <Media
        query="(max-width: 992px)"
        render={() =>
          <Border
            mt={"small"}
            mb={"none"}
          />
        }
      />
    </footer>
  )
}

export default ArticleFooter;
