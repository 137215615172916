import React, { Fragment } from "react";
import {
  Copy,
  Hero,
  NavItem,
  SectionFull,
  Sidebar,
  variables,
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import { noComma } from "../../common/utils";

import Asides from '../Asides/Asides';
import SubMenu from '../Nav/SubMenu';
import Layout from '../Layout/Base'
import Actions from '../Actions/Actions'

import Sticky from "react-stickynode";

const Page = (props) => {
  const { page: p } = props;

  const { colors, fontWeight } = variables;

  return [
    <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
      {p.parent && (
        <SubMenu>
          <NavItem
            fontWeight={fontWeight.medium}
            color={colors.textBase}
            fs={"13px"}
            lh={"16px"}
          >
            <Link to={`/${p.parent.slug}`}>{p.parent.title.rendered}</Link>
          </NavItem>
          {p.parent.children &&
            p.parent.children.map((c) => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.parent.slug}/${c.slug}`}>
                  {c.title.rendered}
                </Link>
              </NavItem>
            ))}
        </SubMenu>
      )}
    </Sticky>,
    <Hero
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
      bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      mb={
        p.hero && p.hero.background_image && p.hero.background_image.url
          ? "large"
          : "none"
      }
      center={p.hero && p.hero.cta_type === "Countdown" ? true : null}
    >
      {p.hero.cta_type === "Countdown" ? (
        <Actions
          type={p.hero.cta_type}
          headline={p.hero.countdown.headline}
          date={p.hero.countdown.date}
          label={
            p.acf.hero.button &&
            p.acf.hero.button.link_label &&
            p.acf.hero.button.link_label
          }
          color={
            p.acf.hero.button &&
            p.acf.hero.button.link_color &&
            p.acf.hero.button.link_color
          }
          target={
            p.acf.hero.button &&
            p.acf.hero.button.link_target &&
            p.acf.hero.button.link_target
          }
          url={
            p.acf.hero.button &&
            p.acf.hero.button.link_url &&
            p.acf.hero.button.link_url
          }
        />
      ) : null}
    </Hero>,
    <Fragment>
      {p.content.rendered && (
        <SectionFull mt={"none"}>
          <div className={"col-lg-7"}>
            <article className="entry">
              <Copy intro html={p.content.rendered} />
              {p.acf.layout_content && p.acf.layout_content.length ? (
                <Layout layout={p.acf.layout_content} />
              ) : null}
            </article>
          </div>

          <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
        </SectionFull>
      )}
    </Fragment>,
    <Fragment>
      {p.acf.layout && p.acf.layout.length ? (
        <Layout layout={p.acf.layout} />
      ) : null}
    </Fragment>,
  ];
};

export default Page;
